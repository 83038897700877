@import "../../styles/variables";

.deciding-interface {
  position: relative;
  width: 100%;
  min-height: 100%;
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  display: flex;
  flex: 1 0 100%;

  .deciding-game-zone {
    display: flex;
    flex-direction: column;
    flex: 1 0 100%;
    box-sizing: border-box;
    padding: 0;
    position: relative;

    .component-board {
      flex: 1 0 auto;
    }

    .component-info-panel {
      flex: 0 1 150px;
      font-weight: bold;
      border-top: 1px solid $paleblue;

      &.info-panel-solo {
        flex-basis: 60px;
      }

      .side-info {
        background-color: $bluebg;

        .progress-tournament-rules {
          margin-top: 17px;
          opacity: 1;
          padding: 0 10px;
        }
      }

      .side-info .component-timer .countdown {
        font-weight: bold;
      }
    }

    .deciding-status-message {
      padding-top: 80px;

      .rps-icon {
        display: block;
        font-size: 64px;
      }
    }
  }

  .debug-buttons {
    display: block !important;
    position: fixed;
    bottom: 0;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  }
}
