.progress-tournament-rules {
  font-weight: bold;
  opacity: 0.8;
  font-size: 13px;
  margin-top: 5px;
  vertical-align: middle;

  .rps-icon {
    margin-left: 10px;
    margin-right: 3px;

    &:first-child {
      margin-left: 0;
    }
  }

  > div {
    display: inline-block;
    margin-left: 8px;
  }
}
