@import "../../styles/variables";

#top-nav {
  .container {
    background-color: transparent;
  }

  .site-logo {
    display: none;
    position: relative;
    border-left: 0;
    border-right: 0;
    left: -13px;
    box-shadow: none;

    &::before {
      display: none;
    }
  }

  .group-name-menu {
    position: relative;
    display: inline !important;

    &.group-name-menu-no-members {
      > span.group-name-menu-name {
        top: 2px;
      }
    }

    > span {
      max-width: 200px;
      display: inline-block;
      height: 36px;
      overflow: hidden !important;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
      vertical-align: bottom;

      &.group-name-menu-name {
        position: absolute;
        line-height: 16px;
        top: -5px;
      }

      &.group-name-menu-members {
        font-size: 12px;
        font-weight: normal;
        line-height: 12px;
        position: absolute;
        top: 13px;
        opacity: 0.8;
      }
    }
  }

  .random-logo {
    > span {
      display: inline-block;

      &.beta {
        font-size: 15px;
        font-style: italic;
        font-weight: bold;
        margin-left: 2px;
        opacity: 1;
        color: $yellow;
      }
    }
  }

  .notification-bell {
    display: inline-block;
    position: relative;

    .rps-icon {
      font-size: 20px;
      line-height: 20px;
      position: relative;
      top: -1px;
      cursor: pointer;
    }

    .notification-bell-badge {
      position: absolute;
      top: -5px;
      right: -8px;
      min-width: 17px;
      height: 17px;
      border-radius: 17px;
      background-color: $red;
      color: #fff;
      font-size: 11px;
      line-height: 17px;
      text-align: center;
      font-weight: bold;
      padding: 0 2px;
      cursor: pointer;
    }
  }

  #dropdown-account {
    .user-dropdown {
      width: 260px;
      min-width: 260px;
      z-index: 2000;
      visibility: hidden;
      right: -20px !important;
      left: initial !important;
    }
    
    &:focus-within .user-dropdown {
      transform: scaleY(1);
      visibility: visible;
    }

    .dropdown-account-trigger {
      //min-width: 100px;
      width: 30px;
      height: 24px;
      .rps-icon {
        font-size: 24px;
        line-height: 24px;
      }

      .ui.placeholder.avatar-placeholder {
        width: 35px;
        height: 35px;
        border-radius: 35px;
        display: inline-block;

        .image {
          width: 35px;
          height: 35px;
        }
      }

      .ui.placeholder.username-placeholder {
        margin-top: 0;
        display: inline-block;
        width: 60px;
        height: 15px;
        position: relative;
        top: -8px;
        left: 7px;
      }
    }
  }

  .menu {
    .item.user-dropdown-item {
      min-height: 40px;
      font-size: 16px;
      line-height: 16px;
      padding-top: 20px !important;
      padding-bottom: 15px !important;
    }

    .item {
      padding-left: 15px !important; 
      font-size: 16px;
      line-height: 16px;

      &:last-child {
        margin-bottom: 7px;
      }

      span.rps-icon {
        display: inline-block;
        margin-right: 4px;
      }
    }
  }
}
