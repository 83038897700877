@import "../../styles/variables";

.notifications-list {
  div.no-notifications {
    font-size: 16px;
    text-align: center;
    padding: 2em 0;

    .text-button {
      margin-top: 20px;
    }

    h3 {
      margin-bottom: 20px;
    }
  }

  .enable-notifications-prompt-btn {
    text-align: center;
    padding: 20px 0;
    background-color: white;
    margin: 0;
    border-bottom: 1px solid rgba(34,36,38,.1);
  }

  .notifications-list-items {
    .ui.vertical.menu {
      width: auto;
      border: 0;

      .ui.image.label {
        img {
          max-height: 17px;
          margin-top: -3px;
        }
      }

      .notifications-list-item.item {
        padding: 15px 1em;
        display: flex;
        align-items: center;

        &.active {
          background: none;
          position: relative;
          overflow: hidden;
          background-color: rgba(0, 0, 0, 0.16);

          &::before {
            display: block !important;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
          }
        }

        .notification-icon {
          flex: 0 0 44px;
          width: 44px;
          height: 35px;
          margin-right: 13px;
          text-align: left;

          .rps-icon {
            color: $green;
            font-size: 35px;
            line-height: 35px;
          }

          &.penalty {
            .rps-icon {
              color: $red;
            }
          }
        }

        .notification-content {
          flex: 1 1 auto;
        }

        h5 {
          margin-bottom: 4px;
          font-size: 15px;
        }

        img {
          width: 29px;
          height: 29px;
          margin: 0 7px 0 0;
        }

        .notification-body {
          color: #333;

          > div {
            font-size: 14px;
            line-height: 18px;

            span {
              &.datetime {
                white-space: nowrap;
              }

              &.avatar {
                font-weight: bold;
                margin-right: 3px;

                .rps-avatar-wrapper {
                  width: 20px;
                  height: 20px;
                  margin-right: 2px;
                  position: relative;
                  top: -1px;
                }
              }

              &.username {
                font-weight: bold;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .notification-timeago {
          margin: 7px 0 0;
          font-size: 12px;
          line-height: 12px;
          color: #999;
        }
      }
    }
  }
}
