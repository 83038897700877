@import "../../styles/variables";

.notify-bar {
  display: flex;
  align-items: center;

  padding: 10px;
  width: 100%;
  margin: 5px 0;
  font-size: 13px;
  line-height: 17px;
  background-color: $red;
  color: #ffffff;
  text-align: left;

  .ui.basic.button {
    margin: 10px auto 0 !important;
    width: auto !important;
    display: inline-block;
    color: white;
    border-color: white;
    box-shadow: 0 0 0 1px rgba(255,255,255,.85) inset;

    &:hover {
      text-decoration: underline;
      color: #eee;
      background-color: transparent;
      box-shadow: 0 0 0 1px rgba(255,255,255,.85) inset;
    }
  }

  .notify-bar-text {
    flex: 1 1 auto;
  }

  .notify-bar-buttons {
    flex: 0 0 auto;
    padding-left: 15px;
  }

  &.notify-stacked {
    display: block;
    text-align: center;

    .notify-bar-text {
      text-align: center;
    }

    .notify-bar-buttons {
      text-align: center;
      padding-top: 30px;
      padding-left: 0;
    }
  }
}
