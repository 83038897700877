@import "../../styles/variables";

#rps {
  display: none !important;
}

#app {
  background-color: #f6f6f6;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  #top-nav {
    width: 100%;
    padding: 10px 0 0;
    margin: 0;
    color: white;
    flex: 0 0 56px;
    border: 0;

    &.page-theme-default {
      background-color: $blue;
    }

    &.page-theme-tournament {
      background-color: $gametournament;
    }

    &.page-theme-solo {
      background-color: $gamesolo;
    }

    &.page-theme-friends {
      background-color: $gamefriends;
    }

    .container {
      > a.item,
      > .menu > a.item {
        color: white !important;
      }
    }

    .fa-circle-arrow-left {
      font-size: 26px;
      line-height: 26px;
      position: relative;
      top: 4px;
    }

    .site-logo > div {
      margin-left: -2px;

      > span {
        &.logo-heading {
          font-weight: bold;
          font-size: 19px;
          line-height: 19px;
          letter-spacing: 0.2px;
        }

        &.logo-subheading {
          font-size: 11px;
          line-height: 11px;
        }
      }
    }

    .ui.container {
      > .item {
        height: 46px;
        margin: 0 15px 0 0;
        padding: 0;
        line-height: 36px;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
      }

      .item::before {
        display: none !important;
      }
    }

    .right.menu {
      position: absolute;
      top: 15px;
      right: 18px;
    }
  }

  #main {
    width: 100%;
    flex: 1 1 calc(100% - 56px);
    padding: 0;
    margin: 0;
    //overflow-y: scroll;
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .game-mode-text-color {
      color: $blue;
    }

    &.page-theme-tournament {
      .game-mode-heading-color {
        color: $gametournament;
      }

      .game-mode-text-color {
        color: $gametournamenttext;
      }

      .ui.primary.button {
        background-color: $gametournament;

        &:hover,
        &:active {
          background-color: $gametournamentdark;
        }
      }

      .ui.primary.button.basic {
        background-color: white;
        box-shadow: 0 0 0 1px $gametournamentdark inset;
        color: $gametournament;

        &:hover,
        &:active {
          background-color: $gametournamentdark;
          color: white;
        }
      }
    }

    &.page-theme-solo {
      .game-mode-heading-color {
        color: $gamesolo;
      }

      .game-mode-text-color {
        color: $gamesolotext;
      }

      .ui.primary.button {
        background-color: $gamesolo;

        &:hover,
        &:active {
          background-color: $gamesolodark;
        }
      }

      .ui.primary.button.basic {
        background-color: white;
        box-shadow: 0 0 0 1px $gamesolodark inset;
        color: $gamesolodark;

        &:hover,
        &:active {
          background-color: $gamesolodark;
          color: white;
        }
      }

      .component-nameplate {
        width: 50px;

        .nameplate-panel {
          width: 50px;
          text-align: center !important;
          left: 0 !important;
        }
      }
    }

    &.page-theme-friends {
      .game-mode-heading-color {
        color: $gamefriends;
      }

      .game-mode-text-color {
        color: $gamefriendstext;
      }

      .ui.primary.button {
        background-color: $gamefriends;

        &:hover,
        &:active {
          background-color: $gamefriendsdark;
        }
      }

      .ui.primary.button.basic {
        background-color: white;
        box-shadow: 0 0 0 1px $gamefriendsdark inset;
        color: $gamefriendsdark;

        &:hover,
        &:active {
          background-color: $gamefriendsdark;
          color: white;
        }
      }
    }

    .page {
      display: flex;
      width: 100%;
      min-height: 1%; // this is necessary to stop layout breaking, i do not know why
      padding: 20px 0;
      margin: 0;
      box-sizing: border-box;

      #content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        height: 100%;
        padding: 0;
        margin: 0;
        overflow-x: hidden;
        overflow-y: auto;
        position: absolute;

        // > div {
        //   width: 100%;
        //   flex: 1 1 auto;
        // }

        #footer {
          width: 100%;
          flex: 0 1 auto;
          text-align: center;
          box-sizing: border-box;
          padding: 40px 0;
        }
      }

      .inner-content {
        .action-panel {
          //position: absolute;
          //bottom: 0;
          //left: 0;
          //right: 0;
          border-top: 1px solid #ddd;
          padding: 15px;

          > .ui.button,
          > .action-panel-btn-wrap {
            margin: 0 0 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .ui.button {
            display: block;
            width: 100%;

            .rps-icon {
              margin: 0 5px;
              position: relative;
              top: 0px;
            }
          }
        }
      }
    }
  }
}

sup {
  top: -0.3em;
}

.rps-avatar-wrapper {
  position: relative;
  display: inline-block;

  .rps-avatar {
    width: 100% !important;
    height: 100% !important;

    .ui.image {
      vertical-align: unset;
    }

    &.rps-avatar-square {
      border-radius: 0 !important;
    }
  }

  .rps-avatar-loader {
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: calc(50% - 8px);
    left: calc(50% - 10px);
    font-size: 16px;
    line-height: 16px;
    opacity: 0.3;
    color: #333;
  }
}

#dropdown-account {
  .rps-avatar {
    margin-right: 5px;
    width: 35px;
    height: 35px;
  }

  span.rps-username {
    font-size: 14px;
    position: relative;
    top: 1px;
  }

  .dropdown.icon {
    display: none;
  }
}

#desktop-frame {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: darken(#4281a4, 20%);
  padding: 50px 0 0;

  #desktop-info {
    flex: 0 0 500px;
    width: 500px;
    height: 740px;
    max-height: 100%;
    color: white;
    text-align: center;
    margin: 0 70px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    h1 {
      margin: 0 0 20px;
    }

    h4 {
      font-weight: normal;
      opacity: 0.9;
    }

    .qr-code {
      margin: 0 auto 50px;
      background-color: white;
      width: 216px;
      height: 216px;
      padding: 8px;

      & .qr-svg rect {
        fill: darken(#4281a4, 20%);
      }
    }

    .rko-logo {
      margin: 0 auto 15px;
      font-size: 24px;
      line-height: 24px;

      i {
        display: inline-block;
        font-size: 18px;
        margin-left: 3px;
        opacity: 0.8;
      }
    }

    .rko-beta {
      font-size: 18px;
      line-height: 18px;
      margin: 0 auto 30px;

      .text-button {
        color: lighten(#4281a4, 40%) !important;

        &:hover {
          color: white !important;
        }
      }
    }

    .desktop-arrow {
      position: absolute;
      bottom: 20px;
      right: -50px;
      width: 100%;
      font-size: 16px;
      text-align: right;
      opacity: 0.9;

      .rps-icon {
        font-size: 24px;
        position: relative;
        top: 4px;
      }
    }
  }

  #desktop-content {
    flex: 0 0 342px;
    padding: 0;
    width: 342px;
    height: 760px;
    max-height: 100%;
    position: relative;

    > img {
      position: absolute;
      top: -16px;
      left: -16px;
      width: 377px;
      height: 795px;
      max-height: 795px;
      z-index: 9999999;
      pointer-events: none;
    }

    #desktop-content-inner {
      width: 342px;
      height: 760px;
      max-height: 100%;
      overflow: auto;
      position: relative;
    }
  }
}

#browser-cta {
  text-align: center;

  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  //justify-content: center;

  img.rko-logo {
    margin: 170px auto 30px;
    max-height: 100px;
    flex: 0 1 260px;
  }

  .qr-code {
    text-align: center;
    flex: 1 0 auto;
    color: #555;

    img {
      margin: 0 auto 25px;
      border: 1px solid #bbb;
      padding: 10px;
      background-color: white;
    }
  }

  .client-footer {
    flex: 0 1 60px;
    font-size: 16px;
    font-weight: bold;
  }
}

#hub-reconnecting {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 3px 5px;
  text-align: center;
  font-size: 12px;
  line-height: 12px;

  .rps-icon {
    margin-right: 3px;
  }
}

@media (max-width: 939px) {
  #desktop-frame {
    padding: 0;

    #desktop-info {
      display: none;
    }
  }
}
