.ui.modal.modal-install-app-enable-notifications {
  > div.content {
    max-height: 600px;
    overflow-y: auto;

    .iaen-intro {
      font-size: 16px;
      line-height: 22px;
      text-align: center;
      margin: 0 0 25px;
    }

    .text-buttons {
      text-align: center;
      margin-top: 20px;
    }
  }
}
