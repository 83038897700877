@import "../../../styles/_variables.scss";

.component-info-panel {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  padding: 0;
  height: 90px;

  display: flex;
  align-items: stretch;

  .side-info {
    flex: 1 1 auto;

    .info-lg {
      display: block;
      font-size: 26px;
      line-height: 26px;
      margin: 21px 0 16px;
    }
  }

  .reminder-bell {
    position: absolute;
    top: 35px;
    left: 30px;
    right: 0;
    padding-left: 160px;
  }
}

#board-overlay {
  .component-info-panel {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0;
    right: 0;
    pointer-events: none;

    .side-info {
      flex: none;
      margin: 0 auto;
      width: 100%;
      pointer-events: all;

      .component-timer {
      }

      .info-lg {
        display: block;
        font-size: 26px;
        line-height: 26px;
        margin: 21px 0 16px;
      }
    }

    .reminder-bell {
      .notification-btn {
        .text-button {
          color: #fff;
        }
      }
    }
  }
}