@import "../../../styles/variables";

.group-members-list {
  .group-members-list-list.show-match-grouping {
    .item.group-member {
      &.group-member-match-top {
        margin-top: 3px;
        margin-bottom: 6px;
        position: relative;

        &::after {
          position: absolute;
          bottom: -5px;
          left: 24px;
          width: 10px;
          height: 10px;
          content: "vs";
          font-size: 10px;
          color: #666;
          visibility: visible;
          font-weight: bold;
        }
      }

      &.group-member-match-bottom {
        margin-bottom: 10px;
        padding-bottom: 12px !important;
        border-bottom: 1px solid #e6e6e6;
      }

      &.group-member-match-none {
        & + .group-member-match-top {
          border-top: 1px solid #e6e6e6;
          padding-top: 6px !important;
        }
      }
    }
  }

  .item.group-member {
    padding: 3px 1em !important;
    position: relative;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    > div {
      display: flex;

      &.group-member-deleted {
        opacity: 0.5;
      }
    }

    &.vs-ai {
      .content > span {
        position: relative;
        height: 30px;

        .vs-ai {
          position: absolute;
          left: 0;
          bottom: -6px;
          font-size: 12px;
          color: $blue;
          font-weight: bold;

          .help-node {
            color: $blue;
            position: relative;
            top: -2px;
            left: -2px;
          }
        }
      }
    }

    &.is-active {
      background-color: #fafafa;
      border-top-color: #ddd;
      border-bottom-color: #ddd;
    }

    &.group-member-not-member {
      .rps-avatar-wrapper {
        opacity: 0.8;
      }
    }

    .content {
      color: #333 !important;
      font-size: 16px;
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      position: relative;

      > span {
        display: block;
        flex: 1 1 auto;
      }
    }

    .ui.label {
      position: relative;
      top: -1px;
      margin-left: 5px;
    }

    .presence {
      display: none;
      position: absolute;
      bottom: 2px;
      left: 20px;
      width: 12px;
      height: 12px;
      background-color: #999;
      border: 2px solid white;
      border-radius: 8px;

      &.online {
        background-color: $green;
      }
    }

    .rps-avatar-wrapper {
      width: 30px;
      height: 30px;
      margin-right: 5px;
      flex: 0 0 30px;
    }

    .group-member-controls {
      display: block;

      .group-member-button-group {
        padding: 10px 0 5px;
        display: flex;

        .ui.button {
          flex: 1 0 50%;
        }
      }
    }
  }
}

.group-member-providers {
  flex: 0 1 auto;

  > span.authed-with {
    font-size: 12px;
    display: inline-block;
    margin-right: 3px;
    position: relative;
    top: -1px;
    color: #777;
  }

  .rps-icon {
    margin: 0 3px;

    .fa-facebook {
      color: $fbbluelight;
    }

    .fa-twitter {
      color: $twbluelight;
    }

    .fa-google {
      color: $googlered;
    }

    .fa-microsoft {
      color: $microsoftblue;
    }

    .fa-snapchat {
      color: $snapchatyellow;
    }
  }
}
