@import "../../styles/variables";

.group-members {
  padding: 10px 0;
  height: 100%;
  text-align: left;

  display: flex;
  flex-direction: column;

  .group-members-list {
    flex: 1 1 auto;
    margin-top: 5px;
    position: relative;
    padding-bottom: 40px;

    .group-members-chosen-list {
      border-bottom: 1px solid #e3e3e3;
      padding-bottom: 25px;
      margin-bottom: 10px;
    }

    .group-members-list-list {
      &.chosen-ones,
      &.non-chosen-ones {
        .item.group-member {
          padding: 6px 1em !important;

          .content {
            > span {
              font-size: 18px;
            }
          }

          .rps-avatar-wrapper {
            width: 40px;
            height: 40px;
            margin-right: 10px;
            flex: 0 0 40px;
          }
        }

        &.group-members-list-list-small {
          .item.group-member {
            padding: 3px 1em !important;

            .content {
              > span {
                font-size: 16px;
              }
            }

            .rps-avatar-wrapper {
              width: 30px;
              height: 30px;
              margin-right: 7px;
              flex: 0 0 30px;
            }
          }
        }
      }

      &.chosen-ones {
        .item.group-member {
          .content {
            > span {
            }
          }
        }
      }
    }

    .group-members-buttons {
      position: absolute;
      top: -2px;
      right: 0.9em;

      .group-members-button {
        height: 39px;
        min-width: 39px;
        margin-left: 5px;
        position: relative;
        display: inline-block;

        .ui.button {
          height: 39px;
          min-width: 39px;
        }

        .button-description {
          position: absolute;
          left: 0;
          right: 5px;
          bottom: -20px;
          font-size: 11px;
          font-weight: bold;
          text-align: center;
          color: #444;
        }
      }
    }

    .ui.grid {
      &.group-members-list-heading {
        padding: 0 1em;
        position: relative;

        &.has-status {
          margin-bottom: 0px;
        }

        &.has-rules {
          margin-bottom: 0px;
        }

        &.has-rules.has-status {
          margin-bottom: 30px;

          .group-members-list-heading-tournament-status {
            bottom: 1px;
          }

          .progress-tournament-rules {
            bottom: -20px;
          }
        }

        .group-members-list-heading-tournament-status {
          font-size: 13px;
          color: $blue;
          margin-top: 0px;
          position: absolute;
          left: 1rem;
          bottom: 4px;

          .rps-icon {
            margin-right: 4px;
          }
        }

        .progress-tournament-rules {
          position: absolute;
          left: 1rem;
          bottom: 0px;
        }

        span.sub-heading {
          font-size: 13px;
          color: #666;
          font-weight: normal;
          margin-left: 3px;
        }

        .column {
          padding-top: 10px;
          padding-bottom: 5px;
        }
      }

      &.group-members-list-list {
        .column {
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }

    h5.ui.header {
      margin-bottom: 5px;
      font-size: 14px;
      line-height: 44px;
      color: #444;
    }
  }
}
