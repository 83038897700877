@import "../../../../styles/variables";

#board-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  box-sizing: border-box;
  transition: all;
  overflow: hidden;
  user-select: none;
  //pointer-events: none;
  transition: opacity 500ms ease;
  display: flex;
  flex-direction: column;

  &.hide {
    display: none;
  }

  &.show {
    display: flex;
  }

  > div {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .board-overlay-actions {
    display: block;
    position: relative;
    pointer-events: all;
    margin-top: 40px !important;

    > div {
      display: inline-block;

      &.board-overlay-countdown {
        position: relative;
        top: 8px;
        left: 5px;
      }
    }

    .ui.small.button {
      .rps-icon {
        position: relative;
        top: -1px;
        margin-right: 0;
        margin-left: 5px;
        font-size: 11px;
      }
    }
  }

  .board-overlay-content {
    padding: 0;
    font-size: 15px;
    line-height: 24px;
    //text-shadow: 0px 0px 2px black;
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    width: 100%;
    max-height: 100%;
    box-sizing: border-box;
    position: relative;

    .action-panel {
      flex: 0 1 78px !important;
    }

    .board-overlay-scroll-wrap {
      padding: 25px;
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      width: 100%;
      max-height: 100%;
    }

    .side-info {
      background-color: transparent !important;
    }

    .board-overlay-view {
      flex: 1 0 auto;

      > div {
        margin: 10px 0;
      }

      .component-info-panel {
        position: static;
        margin-top: 20px;
        border-top: 0;

        .component-timer .countdown {
          font-weight: bold;
        }
      }

      .board-overlay-view-2 {
        margin-top: 40px;
      }

      .board-overlay-timer {
        flex: 0 0 auto;
        height: 90px;
      }

      .board-overlay-heading {
        font-size: 42px;
        line-height: 42px;
        font-weight: bold;
        margin: 10px 0 30px;
        color: $blue;
      }

      .board-overlay-subheading {
        font-size: 16px;
        line-height: 18px;
        font-weight: bold;
        margin: 0 0 5px;

        &.board-overlay-last-game-result {
          margin-top: -25px;
          font-weight: normal;
          font-size: 14px;
        }
      }

      .board-overlay-decision {
        font-size: 14px;
        margin-bottom: 30px;
        margin-top: 0;

        .board-overlay-stake-positive {
          color: $green;
        }

        .board-overlay-stake-negative {
          color: $red;
        }

        .board-overlay-stake {
          font-weight: bold;
          font-size: 16px;
        }
      }

      .board-overlay-stake-is {
        font-size: 16px;
      }
      .board-overlay-players {
        font-size: 18px;
      }
      .board-overlay-rules {
        font-size: 14px;
        margin: 30px 0 0;
      }

      .board-overlay-remaining {
        margin-top: 50px;

        > span {
          font-family: "Bebas Neue";
          font-size: 64px;
          line-height: 70px;
          text-transform: uppercase;
        }
      }

      .board-overlay-in-progress {
        .board-overlay-in-progress-title {
          font-size: 13px;
          display: block;
          margin: 0;
          font-weight: bold;

          .rps-icon {
            color: $green;
            margin-right: 5px;
          }
        }
      }

      .board-overlay-result {
        margin-top: 30px;
        font-size: 20px;
      }

      .board-overlay-match-summary {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: stretch;
        align-content: stretch;
        margin: 40px 0 0;

        > div {
          text-align: center;
        }

        .board-overlay-ms-player {
          width: 150px;
          position: relative;

          .board-overlay-ms-player-name {
            font-size: 18px;
            line-height: 18px;
            margin: 15px 0 15px;
          }

          .board-overlay-ms-player-score {
            font-size: 50px;
            font-family: "Bebas Neue";
            text-transform: uppercase;
            line-height: 50px;
          }

          .board-overlay-ms-move {
            position: absolute;
            top: 50px;
            right: 5px;

            > img {
              width: 60px;
              height: 60px;
            }
          }

          &.player-right {
            .board-overlay-ms-move {
              right: unset;
              left: 5px;
            }
          }
        }

        .board-overlay-ms-v {
          font-size: 14px;
          width: 50px;
          padding-top: 111px;
          color: #666;
        }
      }

      .board-overlay-status-big {
        margin-top: 30px;
        font-size: 18px;
        line-height: 24px;
      }

      .wait-for-round {
        margin: 20px 0 0;
        color: #666;
      }
    }

    .rps-avatar-wrapper {
      width: 100px;
      height: 100px;
    }
  }
}

.subpage-group
  #subpage-content
  div.inner-content
  #board-overlay
  .board-overlay-content
  .board-overlay-view
  .action-panel {
  position: absolute !important;
  bottom: 0;
  left: 0;
  right: 0;
  margin-bottom: 0;
}
