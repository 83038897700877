@import "../../styles/variables";

.component-decision-history {
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  &.decision-history-viewing-detail {
    opacity: 0;
    pointer-events: none;
  }

  > div.group-tabs {
    flex: 0 1 auto;
    display: flex;

    div.group-tab {
      flex: 0 0 33.33% !important;
      height: 42px;
    }
  }

  .decision-history-list-wrap {
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    h3 {
      margin: 0 0 30px;
    }

    div.no-decisions {
      font-size: 16px;
      text-align: center;
      padding: 2em 0;
    }

    .action-panel {
      display: block;
      position: static;
    }

    > div.decision-history-list {
      flex: 1 1 auto;

      .no-more-tournaments {
        padding: 20px 20px 25px;
        text-align: center;
        color: #666;
      }

      .decision-history-item {
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        margin-bottom: 3px;
        padding: 5px 15px;
        border-bottom: 1px solid #ccc;
        font-size: 14px;
        line-height: 25px;
        cursor: pointer;

        &:first-child {
          padding-top: 13px;
        }

        &:last-child {
          border-bottom: 0;
        }

        > div {
          display: flex;
          margin: 0 0 0px;
          width: 100%;
          max-width: 100%;

          &.wrap {
            flex-wrap: wrap;
          }

          > div.creator {
            flex: 0 0 auto;
            width: 60%;

            .rps-avatar-wrapper {
              width: 25px;
              height: 25px;
              vertical-align: middle;
              position: relative;
              top: -3px;
            }
          }

          > span {
            display: block;
            width: 50%;

            &.end-time {
              width: 40%;
              flex: 0 0 auto;
              color: #888;
              text-align: right;
            }

            &.stake {
              flex: 1 1 auto;
              font-weight: bold;
              text-align: left;
              width: unset;
              max-width: 50%;
            }

            &.participants {
              flex: 0 1 auto;
            }

            &.participating {
              flex: 0 0 auto;
              text-align: right;
              font-weight: bold;

              &.am-participating {
                color: $green;
              }

              &.not-participating {
                color: $red;
              }

              .rps-icon {
                margin-right: 3px;
              }
            }

            &.chosen-one {
              flex: 1 1 auto;
              min-width: 50%;
              text-align: right;
              width: unset;
              padding-left: 10px;

              .text-button {
                cursor: pointer;
                color: $blue;
              }

              .rps-avatar-wrapper {
                width: 25px;
                height: 25px;
                vertical-align: middle;
                margin-left: 5px;
                position: relative;
                top: -3px;
              }
            }

            &.chosen-ones,
            &.non-chosen-ones {
              flex: 0 0 auto;
              text-align: center;
              width: 100%;
              padding-top: 5px;

              > span {
                display: inline-block;
                margin: 0 5px;
              }

              .rps-avatar-wrapper {
                width: 25px;
                height: 25px;
                vertical-align: middle;
                margin-left: 5px;
                position: relative;
                top: -3px;
              }
            }
          }
        }
      }
    }
  }
}

.component-decision-results {
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: visible;
  flex-direction: column;

  &.decision-results-big-winner {
    .group-upper {
      flex-basis: 375px !important;
      border-bottom: 1px solid #ddd;
    }

    .group-lower {
      flex-basis: calc(100% - 375px) !important;
    }
  }

  &.decision-results-no-big-winner {
    .group-upper {
      flex-basis: auto !important;
      border-bottom: 1px solid #ddd;
    }

    .group-lower {
      flex-basis: auto !important;
    }
  }

  .group-upper {
    flex-basis: 325px !important;
    border-bottom: 1px solid #ddd;
  }

  .group-lower {
    flex-basis: calc(100% - 325px) !important;
  }

  .decision-rescheduled {
    margin-top: 0;
    color: $red;
    font-size: 12px;
  }

  .decision-results-replay {
    margin: 15px 0;
  }

  .group-members {
    padding: 0;

    h5.ui.header {
      padding-top: 10px;
      line-height: 25px;
    }
  }

  .decision-results-winner {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
      margin-bottom: 5px;
      flex: 0 0 auto;
    }

    > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;

      .decision-results-winner-avatar {
        flex: 0 0 auto;
        position: relative;
        width: 65px;
        height: 65px;
        margin: 0 10px 0 0;
        overflow: hidden;
        border-radius: 50%;
        border: 2px solid rgba(255, 255, 255, 0.6);

        .rps-avatar-wrapper {
          width: 100%;
          height: 100%;
          border-radius: 0;

          .rps-avatar {
            object-fit: cover;
          }
        }
      }
    }

    h5 {
      flex: 0 1 auto;
      margin: 0;
      font-size: 20px;
    }

    span.skipped-final {
      font-size: 14px;
      line-height: 14px;
      display: inline-block;
      margin-top: 0;
      margin-left: 10px;
      color: $orange;
      position: relative;
      top: -2px;
    }
  }
}
@keyframes pulsateEffectWin {
  0%,
  100% {
    box-shadow: 0 0 0 0px #ffd700, 0 0 8px 10px rgba(255, 223, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 0 0px #ffd700, 0 0 16px 10px rgba(255, 223, 0, 0.5);
  }
}
@keyframes pulsateEffectLose {
  0%,
  100% {
    box-shadow: 0 0 0 0px $red, 0 0 8px 10px rgba(255, 0, 0, 0.1);
  }
  50% {
    box-shadow: 0 0 0 0px $red, 0 0 16px 10px rgba(255, 0, 0, 0.3);
  }
}
