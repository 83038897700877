.ui.modal.modal-unblock-notifications {
  > div.content {
    max-height: 600px;
    overflow-y: auto;
    
    ol {
      text-align: left;
      margin: 20px 0 0;
      padding-left: 20px;

      li {
        margin-bottom: 20px;

        img.ui.image {
          margin: 10px 0;
        }
      }
    }
  }
}
