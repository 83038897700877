@import "../../../styles/variables";

.ui.modal.modal-rules {
  &.ui.modal > .scrolling.content {
    //max-height: calc(80vh)
  }
  
  > div.content {
    ul {
      text-align: left;
      padding-left: 15px;
      margin-top: 0;

      li {
        margin-bottom: 5px;

        ul {
          list-style: square outside none;
          padding-left: 15px;
          margin-top: 10px;
          margin-bottom: 20px;

          li {
            margin-bottom: 5px;
          }
        }
      }
    }

    .configurable-value {
      display: inline-block;
      color: $blue;
      border-bottom: 1px dotted $blue;
      padding-bottom: 2px;
      line-height: 1;
      font-weight: bold;
    }
  }
}
