@import "../../../../styles/variables";

.component-timer {
  position: relative;
  box-sizing: border-box;
  margin: 15px 0 10px;

  .enable-notifications-prompt-btn {
    position: absolute;
    left: calc(100% + 10px);
    top: 9px;
  }

  &.component-timer-inline {
    .countdown {
      > div {
        > div {
          margin-right: 3px;
        }
      }
    }
  }

  &.component-timer-large {
    .countdown {
      font-size: 36px;
      line-height: 30px;
      height: 55px;
      font-weight: bold;
    }
  }

  .timer-completed {
    color: #666;
  }

  .countdown {
    display: block;
    margin: 5px 0;
    font-size: 26px;
    line-height: 20px;
    font-weight: normal;
    height: 40px;

    > div {
      > div {
        display: inline-block;
        text-align: center;

        &.colon {
          margin: 0 3px;
          font-weight: normal;
          position: relative;
          top: -2px;
        }

        > span {
          display: block;
          width: 100%;

          &.label {
            font-size: 12px;
          }
        }
      }

      &.description {
        font-size: 14px;
        font-weight: normal;
      }
    }
  }
}