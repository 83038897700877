.ui.modal.modal-invite {
  > div.content {
    text-align: center;
    padding: 40px 20px;

    p {
      margin-bottom: 30px;
    }

    input {
      margin: 15px 0;
      text-align: center;
      font-size: 18px;
    }
  }
}
