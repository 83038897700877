.help-node.help-node-styled {
  display: inline-block;
  margin: 0 5px;
  font-size: 12px;
  line-height: 12px;
  vertical-align: middle;
  color: #333;
  position: relative;
  top: -1px;

  &.inverted {
    color: #ccc;
  }
}
