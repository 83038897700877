.ui.modal.modal-decision-participants {
  > div.content {
    text-align: center;
    padding: 20px 1rem 20px !important;
    overflow-y: auto;

    p {
      margin-bottom: 30px;
    }

    .group-members {
      max-width: 100%;

      h5.ui.header {
        line-height: 14px !important;
      }
    }
    h3 {
      margin: 0 0 35px;
    }

    > h1 {
      margin: 0;
    }

    > h5 {
      margin: 0px 0 12px;

      .rps-avatar-wrapper {
        position: relative;
        top: -1px;
        margin-right: 5px;
        width: 25px;
      }
    }

    span.winners-to-draw {
      color: #555;
      font-size: 13px;
      line-height: 13px;
      display: block;
      margin: 5px 0 25px;
    }
  }
}
