@import "../../../styles/variables";

.group-members-avatar-stack {
  width: auto;
  display: inline-block;
  padding-left: 25px;

  .group-members-avatar-stack-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .nav-chevron {
      flex: 0 0 15px;
      font-size: 18px;
      color: $blue;
      text-align: right;
      margin-left: 15px;
    }

    .new-group-member-tag {
      position: absolute;
      top: 0;
      bottom: 0;
      right: calc(100% + 5px);
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .ui.label {
        flex: 0 1 auto;
        background-color: $blue !important;
        border-color: $blue !important;
      }
    }

    .group-member-element {
      flex: 0 0 30px;
      width: 30px;
      height: 45px;
      overflow-x: visible;

      .rps-avatar-wrapper {
        width: 45px;
        height: 45px;
        border: 2px solid #f6f6f6;
        margin: 0 0 0 0;
        border-radius: 100px;

        .ui.avatar.image {
          margin-right: 0;
        }

        &:last-of-type {
          margin-left: 0;
        }
      }

      .group-members-avatar-stack-more {
        width: 41px;
        height: 42px;
        border: 1px solid #bbb;
        border-radius: 50px;
        margin: 1px 0 0 0;

        > span {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          font-size: 18px;
          color: #999;
          font-weight: bold;
          position: relative;
          left: 2px;
        }
      }
    }

    .group-members-avatar-stack-count {
      flex: 1 0 auto;
      height: 14px;
      line-height: 14px;
      font-size: 14px;
      font-weight: bold;
      text-align: right;
      padding-right: 25px;
      color: #444;
    }
  }
}
