.subpage.subpage-legal {
  //padding-top: 50px !important;
  padding: 25px 20px;

  h1 {
    text-align: center;
    margin: 0 0 25px;
  }
}

.subpage.subpage-faq {
  flex: 1 1 100%;
  overflow-y: auto;

  ul {
    padding: 0 25px;

    li {
      margin-bottom: 10px;
    }
  }
}