@import "../../../styles/variables";

.component-board {
  position: relative;
  width: 100%;
  margin: 0;
  box-sizing: border-box;

  > div {
    height: 100%;

    .main-nameplates {
      position: absolute;
      top: calc(50% - 62px);
      left: 13px;
      height: 120px;
      width: 50%;

      .vs {
        display: none;
        position: absolute;
        top: calc(50% - 10px);
        left: 0;
        font-size: 13px;
        line-height: 18px;
        color: black;
        height: 20px;
        width: 50px;
      }
    }

    .move-histories {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 5px;
      width: 35px;

      display: flex;
      flex-direction: column;

      > div.move-history {
        flex: 0 0 50%;
      }
    }
  }

  #board-canvas {
    margin: 0 -1px;
    height: 100%;

    canvas {
      display: block;
      position: absolute;
    }
  }
}