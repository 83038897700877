@import "../../styles/variables";

.group-list {
  div.no-groups {
    font-size: 16px;
    text-align: center;
    padding: 2em 0;

    h1 {
      margin-bottom: 20px;
    }
  }

  .group-list-header {
    flex: 0 1 auto;
    padding: 0 14px;

    h5 {
      line-height: 24px;
    }

    .ui.divider {
      margin-bottom: 8px;
    }

    .column {
      padding: 14px 14px 5px !important;
    }
  }

  .group-list-items {
    &.group-list-sponsored {
      margin-bottom: 20px;
    }

    .ui.vertical.menu {
      width: auto;
      border: 0;

      .ui.image.label {
        img {
          max-height: 17px;
          margin-top: -3px;
        }
      }

      .group-list-item.item {
        padding: 15px 1em;

        &.group-list-item-creating {
          .group-list-item-name {
            font-style: italic;
            font-weight: normal;
          }
        }

        &.active {
          background: none;
          position: relative;
          overflow: hidden;
          background-color: rgba(0, 0, 0, 0.16);

          &::before {
            display: block !important;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 4px;
            height: 100%;
          }
        }

        img {
          width: 29px;
          height: 29px;
          float: left;
          margin: 0 7px 0 0;
        }

        span.new-activity {
          //display: inline-block;
          display: none;
          font-size: 8px;
          margin-left: 4px;
          position: relative;
          top: -1px;
          color: $green;
        }

        span.am-player {
          float: right;
          font-size: 12px;
          margin-top: 1px;
          position: relative;
        }

        div.group-details {
          display: flex;
          margin: 0 0 5px;

          &:last-child {
            margin-bottom: 0;
          }

          span.group-list-item-name {
            font-weight: bold;
            display: block;
            font-size: 16px;
            line-height: 20px;
            flex: 0 1 auto;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          span.group-list-item-players {
            display: block;
            margin-top: 4px;
            flex: 1 0 55%;
            text-align: right;
            font-weight: bold;

            > div.group-list-item-unseen {
              display: inline-block;
              position: relative;
              width: 80px;

              > .ui.label {
                float: none;
                position: absolute;
                top: -19px;
                right: 0;
                width: 80px;
                border-radius: .28571429rem;
                font-weight: bold;
              }
            }

            .rps-icon {
              margin-left: 10px;
              margin-right: 3px;
            }

            .group-list-item-repeating-value {
              display: inline-block;
              text-align: left;
              width: 17px;
            }

            .group-list-item-players-value {
              display: inline-block;
              text-align: right;
            }
          }

          span.group-list-item-decision {
            display: block;
            flex: 1 0 60%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 16px;
            padding-right: 25px;
          }

          span.group-countdown {
            flex: 0 0 auto;
            text-align: right;
            position: relative;
            color: #666;

            .rps-icon {
              position: absolute;
              top: 1px;
              left: -14px;
            }
          }
        }

        span.group-list-item-decision {
          color: #444;

          .text-green {
            color: $green;
            opacity: 0.8;
          }

          .text-red {
            color: $red;
            opacity: 0.8;
          }
        }
      }
    }
  }
}
