.ui.inline.scrolling.dropdown {
  border-bottom: 1px dotted #333;
  margin: 0 3px;
}

.ui.vertical.menu > .item {
  border-radius: 0 !important;
}

.ui.inverted.button:focus {
  color: #ddd !important;
}

#app #top-nav {
  border-radius: 0;
}

.ui.button {
  border-radius: 0 !important;
}

.ui.input > input {
  border-radius: 0 !important;
}