@import "../../styles/variables";

span.text-button {
  display: inline-block;
  margin: 0 5px;
  padding: 3px 0;
  text-align: center;
  font-weight: bold;
  color: $blue;

  &.flush {
    padding: 0;
    margin: 0;
  }

  &.inverted {
    color: #eee;

    &:hover {
      color: #fff;
    }
  }

  &:hover {
    cursor: pointer;
    color: $primarydark;
  }

  &.disabled {
    opacity: 0.5;

    &:hover {
      color: $primarydark;
    }
  }

  .rps-icon {
    margin-right: 2px;
  }
}
