.page.page-static {
  .static-content {
    padding: 60px 20px;

    img {
      width: 200px;
      margin: 0 auto;
    }

    h1 {
      text-align: center;
      margin-bottom: 40px;
    }
  }
}

#page-about {
  flex: 1 0 auto;
  padding: 0 !important;

  .inner-content {
    display: flex;
    flex-direction: column;

    > div {
      padding: 60px 10px;
      text-align: center;
      flex: 1 0 auto;

      p {
        font-size: 18px;
        line-height: 24px;

        &.about-small {
          font-size: 16px;
          line-height: 22px;
          margin: 20px 0 40px;
        }
      }

      &.action-panel {
        flex: 0 1 auto;
      }
    }
  }
}
