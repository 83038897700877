.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.toggle-text {
  flex: 0 0 90px;
  margin: 0 10px;
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;

  &.active {
    //text-shadow: 0px 0px 1px;
    font-weight: bold;
  }

  &.tt-left {
    text-align: right;

    .rps-icon {
      margin: 0 0 0 7px;
    }
  }

  &.tt-right {
    text-align: left;

    .rps-icon {
      margin: 0 7px 0 0;
    }
  }
}

.toggle-switch {
  width: 64px;
  height: 34px;
  border: 2px solid;
  border-radius: 60px;
  transition: background-color 0.3s, border-color 0.3s;
  margin: 0 3px;
}

.toggle-bg {
  height: 100%;
  width: 100%;
  position: relative;
  border-radius: 50px;
}

.toggle {
  background: rgba(255, 255, 255, 0.9);
  height: 30px;
  width: 30px;
  position: absolute;
  transition: transform 0.3s;
  border-radius: 60px;

  &.left {
    transform: translateX(0);
  }

  &.right {
    transform: translateX(92%);
  }

  &.neutral {
    transform: translateX(50%);
  }
}
