@import "../../styles/_variables.scss";

.tournament-config {
  padding: 20px 1em 0;

  &.no-margin {
    padding: 0;

    h3.ui.header {
      margin-top: 0;
    }

    .component-tournament-rules {
      margin: 0;

      .tr-value {
        margin: 0;
      }
    }
  }

  h5.ui.header.header-editing-repeating {
    margin-bottom: 30px;
    font-size: 1.3rem;

    span {
      font-size: 1rem;
    }
  }

  h3.ui.header {
    margin: 15px 0 10px;
    font-weight: normal;
  }

  span.text-button.new-group-cancel {
    margin-top: 20px;
    color: $red;
  }

  .tr-time {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;

    span {
      margin-right: 5px;
    }

    input {
      width: 150px !important;
    }

    select {
      flex: 1 0 70px;
      margin: 0 5px !important;

      &:first-child {
        margin-left: 0 !important;
      }

      &:last-child {
        margin-right: 0 !important;
      }
    }
  }

  .repeating-config {
    margin-bottom: 10px;
    text-align: left;

    > div.repeating-config-padding {
      display: block;
      width: 250px;
      margin: 0 auto;
    }

    .repeating-custom {
      margin-top: 15px;
    }

    .repeating-every-period {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: left;
      align-items: center;
      align-content: stretch;

      span {
        flex: 0 1 auto;
      }

      input {
        flex: 0 0 100px;
        margin: 0 10px !important;

        &[type="number"] {
          flex-basis: 80px;
        }
      }

      .ui.dropdown {
        flex: 0 0 100px;
      }

      .btn {
        flex: 1 0 auto;
      }
    }

    .repeating-days {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: left;
      align-items: center;
      align-content: stretch;

      span {
        margin-right: 10px;
      }

      .ui.button.circular {
        flex: 0 0 auto;
        padding: 5px !important;
        width: 30px;
        height: 30px;
        text-align: center;
        font-weight: bold;

        &:focus {
          background-color: transparent;
          color: #666;
          box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;

          &.primary {
            background-color: #1678c2;
            color: #fff;
          }
        }
      }
    }

    .repeating-day-of-month {
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: left;
      align-items: center;
      align-content: stretch;

      span {
        margin-right: 5px;

        &.dom-warning {
          color: $red;
          font-size: 12px;
          line-height: 12px;
          text-align: left;
        }
      }

      select {
        flex: 0 0 100px;
        margin: 0 5px !important;
      }
    }
  }

  .component-tournament-rules {
    margin: 25px 0 0;
    padding: 0;

    h5.ui.header {
      margin: 0;
    }

    .ui.grid {
      margin-bottom: 0 !important;
    }

    input[type="date"].tournament-rules-custom-date {
      display: block;
      margin: 0 auto;
      text-align: center;
      width: 100%;
    }

    .tr-value {
      .ui.inline.scrolling.dropdown {
        font-size: 36px;
        line-height: 36px;
        position: relative;

        &.dropdown-sm {
          font-size: 24px;
          line-height: 28px;
        }

        > .text {
          font-weight: 100;
        }

        .dropdown.icon {
          font-size: 12px;
          position: absolute;
          top: 8px;
          right: -21px;
          visibility: hidden;
        }
      }

      margin: 0 0 15px;

      span {
        font-size: 16px;
        text-transform: uppercase;
        font-weight: normal;
        margin-left: 2px;
      }
    }
  }

  .advanced-options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 25px;

    .advanced-option-button {
      flex: 1 1 auto;
      max-width: 200px;

      & + .advanced-option-button {
        margin-left: 15px;
      }

      > .ui.basic.button {
        display: block;
        width: 100%;
      }

      > span {
        display: block;
        font-size: 12px;
        line-height: 12px;
        margin-top: 5px;
      }
    }
  }

  .config-actions {
    margin-top: 15px;
    text-align: center;

    > span {
      display: block;
      font-size: 12px;
      line-height: 12px;
      margin-top: 10px;
    }

    .decide-button-validation {
      color: $red;
    }
  }

  #tournament-config-stake-input {
    text-align: center;
    font-size: 20px;

    &::placeholder {
      font-size: 16px;
    }

    &:focus {
      &::placeholder {
        color: transparent;
      }
    }
  }

  .tournament-config-prize {
    .ui.button.tiny {
      font-size: 13px;

      .rps-icon {
        vertical-align: middle;
        margin-right: 5px;
        font-size: 18px;
      }
    }
  }

  .fb-share-button {
    margin-left: 80px;
    display: inline-block;
    position: relative;
    top: 4px;
  }
}
