@import "../../../styles/_variables.scss";

.ui.modal.modal-stake-info {
  > div.content {
    padding-top: 20px !important;
    padding-bottom: 25px !important;
    text-align: center;

    p {
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 30px;
      margin-top: 25px;

      b {
        display: inline-block;
        font-size: 24px;
        line-height: 30px;
      }
      
      &.stake-sip {
        font-size: 14px;
        margin: 0;
      }

      &.stake {
        font-size: 22px;
        font-weight: bold;
        margin: 20px 0 5px;
        word-break: break-all;
      }
    }
  }
}
