@import "../../styles/_variables.scss";

.subpage-groups {
  display: flex;
  min-height: 100%;
  background-color: #f6f6f6;
  
  #subpage-content {
    div.inner-content {
      width: 100%;
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      max-width: 850px;
      margin: 0 auto;
      padding: 0;
      box-sizing: border-box;
      position: relative;
      

      .groups-container {
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .group-list {
          flex: 1 1 auto;
          overflow-y: auto;
          height: 0; // ?
        }
  
        .action-panel {
          flex: 0 1 auto;
        }
      }
    }

    width: 100%;
    flex-grow: 1;
    position: relative;

    .ui.container {
      display: flex; 
      min-height: 100%;
      background-color: #f6f6f6;
    }

    div.inner-content {
      div.subpage-nav {
        position: absolute;
        top: -50px;
        z-index: 999;
      }
    }
  }
}