@import "../../../styles/variables";

.stake-input {
  .tournament-config-stake {
    position: relative;
    margin-bottom: 0 !important;
    border: 1px solid $blue;

    .stake-wrapper {
      position: relative;

      #tournament-config-stake-input {
        padding: 15px;
        background-color: white;
        border: 0;
        position: relative;
      }

      &::before {
        content: "";
        position: absolute;
        top: -1px; /* Offset by border width */
        left: -1px; /* Offset by border width */
        right: -1px; /* Offset by border width */
        bottom: -1px; /* Offset by border width */
        border: 1px solid white; /* Adjust the color and width as needed */
        border-radius: inherit; /* Match the border-radius of input-element if any */
        box-sizing: border-box;
        animation: stake-border 2s ease infinite;
        -webkit-transform-origin: left;
        transform-origin: left;
      }

      .component-emoji-picker {
        position: absolute;
        right: 10px;
        top: 13px;
        width: 56px;
        height: 56px;
        padding: 10px;

        .picker-button {
          font-size: 36px;
        }
      }

      .suggestions-btn-wrapper {
        position: absolute;
        display: inline-block;
        right: 1em;
        top: 16px;

        .rps-icon {
          font-size: 20px;
          line-height: 20px;
          color: #999;
          /* Light up animation */
          animation: tada, blueTint;
          animation-duration: 2s;
          animation-timing-function: ease-in-out;
          animation-delay: 2s;
          animation-iteration-count: 1;
        }
      }
    }

    .stake-is {
      display: flex;

      .stake-is-button {
        flex: 1 0 auto;
        width: 50%;
        text-align: center;
        font-size: 18px;
        line-height: 18px;
        padding: 15px 0;
        border: 1px solid #777;
        border-top-width: 0;

        .rps-icon {
          margin-right: 5px;
        }

        &.stake-button-positive {
          color: $green;
          border-right-width: 0;
        }

        &.stake-button-negative {
          color: $red;
          border-left-width: 0;
        }
      }
    }
  }

  .stake-updated {
    margin: 0 auto 20px;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: height 300ms ease;
    position: relative;

    > div {
      position: absolute;
      width: 100%;
      bottom: 0;
      display: flex;
      justify-content: center;
    }

    .ui.medium.primary.button {
      flex: 0 0 auto;
      display: block;
      border-radius: 0;
    }

    .ui.medium.basic.button {
      flex: 0 0 auto;
      display: block;
      border-radius: 0;
      margin-left: 1px;
    }

    &.show {
      height: 40px;
    }
  }

  .stake-ideas {
    display: block;
    position: relative;
    font-size: 13px;
    line-height: 23px;
    margin: 0;
    border: 1px solid $border;
    border-radius: 0;
    padding: 10px;

    span.stake-idea {
      display: inline-block;
      font-weight: bold;
      margin: 0 0 0 15px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      span.rps-icon {
        margin-right: 3px;
      }

      &.stake-idea-positive {
        color: $green;
      }
      &.stake-idea-negative {
        color: $red;
      }
    }

    div.more-ideas {
      display: inline-block;
      position: absolute;
      right: 15px;
      margin-left: 15px;
      cursor: pointer;
      color: $grey;

      &:hover {
        color: $black;
      }
    }
  }

  @keyframes blueTint {
    0%, 100% {
      color: #999;
    }
    20%, 90% {
      color: $lightblue;
    }
  }

  @keyframes stake-border {
    0% {
      opacity: 0.7;
      width: 1px;
    }
    100% {
      opacity: 0.2;
      width: 101%;
    }
  }
}
