@import "../../styles/_variables.scss";

#web-view-browser-warning {
  flex: 0 0 44px;

  &.iOS {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;

    .ip-arrow {
      position: relative;
      top: 10px;
      //left: 5px;
    }
  }

  &.Android {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    .ip-arrow {
      position: relative;
      top: -10px;
      left: 3px;
    }
  }

  > div {
    display: flex;
    vertical-align: middle;
    align-items: center;
    background-color: $lightblue;
    padding: 5px 0;

    .ip-text {
      flex: 1 1 65%;
      padding-left: 10px;
      color: white;
      text-align: center;
      line-height: 1.5;
    }

    .ip-button {
      flex: 0 1 auto;
      font-weight: bold;
      color: white;
      padding: 10px 0;

      &:focus {
        background-color: transparent;
      }

      .rps-icon {
        margin-right: 3px;
      }
    }

    .ip-arrow {
        font-size: 24px;
    }

    .ip-dismiss,
    .ip-arrow {
      padding: 10px 20px;
      flex: 0 1 auto;
      margin-left: 10px;
      color: white;
    }
  }
}
