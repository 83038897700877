.page-create-group {
  .create-header {
    border-bottom: 0 !important;
    position: relative;

    .create-header-inner {
      text-align: center;
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;

      font-size: 24px;
      line-height: 36px;
      padding: 0 25px;
    }
  }

  .action-panel {
    border-top: 0 !important;
  }

  .group-creation {
    padding-bottom: 15px;
  }

  .join-wrapper {
    position: relative;

    .ui.button {
      display: block;
      width: 90%;
      margin: 0 auto;
    }
  }

  .notify-bar {
    margin-bottom: 10px;
  }
}
