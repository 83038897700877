.modal-post-registration {

    .rps-avatar-wrapper {
        width: 80px;
        height: 80px;
    }

    h1 {
        text-align: center;
        margin: 20px auto 35px;
        font-size: 22px;
        line-height: 22px;
    }

    h4 {
        font-size: 16px;
        font-weight: normal;
    }

    .ui.input {
        position: relative;

        .rps-icon {
            position: absolute;
            top: 9px;
            left: 15px;
        }
    }

    .ui.form {
        margin-bottom: 20px;
    }

    .join-wrapper {
        position: relative;
        
        .ui.button {
            width: 100%;
        }
    }
    
    .notify-bar {
        text-align: left;
        margin-bottom: 20px;
    }

    .notification-permissions {
        text-align: center;
        margin: 5px 0 0;
        font-size: 12px;
        color: #333;
      }
}