.qr-svg {
  fill: rgb(255, 255, 255);
  fill-opacity: 1;
  width: 100%;
  height: 100%;
  image-rendering: crisp-edges;

  & rect {
    fill: rgb(0, 0, 0);
    fill-opacity: 1;

    &.qr-svg__background {
        fill: rgb(255, 255, 255) !important;
        fill-opacity: 1;
    }
  }
}
