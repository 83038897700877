@import "../../styles/_variables.scss";

.subpage-settings {
  display: flex;
  min-height: 100%;
  background-color: #f6f6f6;

  #subpage-content {
    div.inner-content {
      width: 100%;
      max-width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      max-width: 850px;
      margin: 0 auto;
      padding: 0;
      box-sizing: border-box;
      position: relative;

      h3 {
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      .account-details-list {
        border-bottom: 1px solid #ddd;
        padding-bottom: 10px;

        .provider-icon {
          height: 68.5px;
          
          .sso-icon {
            margin-left: 0;

            &.sso-icon-amazon,
            &.sso-icon-amazon:hover,
            &.sso-icon-email,
            &.sso-icon-email:hover,
            &.sso-icon-google,
            &.sso-icon-google:hover,
            &.sso-icon-proton:hover {
              background: #fff !important;
              border: 1px solid #aaa !important;
            }

            &:hover {
              border: 0 !important;
              outline: 0 !important;
              box-shadow: inset 0 0 rgba(0, 0, 0, 0.16),
                0 2px 5px rgba(0, 0, 0, 0.45) !important;
            }
          }

          .rps-icon {
            font-size: 48px;
            line-height: 48px;
          }
        }

        .ui.placeholder {
          width: 200px;

          .line {
            background-color: transparent;
            height: 13px;
          }
        }

        .adl-item {
          margin: 0 0 11px;

          h5 {
            margin: 0 0 2px;
          }

          p {
            margin: 0;
            height: 20px;
          }

          .rps-avatar-wrapper {
            width: 100px;
            height: 100px;
            margin: 5px 0;
          }
        }
      }

      .settings-container {
        flex: 0 0 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        padding: 1em;

        .ui.input {
          position: relative;

          .rps-icon {
            position: absolute;
            top: 9px;
            left: 15px;
          }
        }

        .settings-action {
          text-align: right;

          .settings-saved {
            font-size: 12px;
            margin-top: 5px;

            .rps-icon {
              margin-right: 5px;
            }
          }
        }

        // .notifications-list {
        //   flex: 1 1 auto;
        //   overflow-y: auto;
        //   height: 0; // ?
        // }

        .action-panel {
          flex: 0 1 auto;
        }
      }
    }

    .settings-notifications {
      position: relative;
      margin: 10px 0;
      text-align: center;
      border-bottom: 1px solid #ddd;
      padding-bottom: 25px;
    }

    form.ui.form {
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    width: 100%;
    flex-grow: 1;
    position: relative;

    .ui.container {
      display: flex;
      min-height: 100%;
      background-color: #f6f6f6;
    }

    div.inner-content {
      div.subpage-nav {
        position: absolute;
        top: -50px;
        z-index: 999;
      }
    }
  }
}
