@import "../../../styles/variables";

.modal-user-manage {
  > div.content {
    text-align: center;
    padding: 40px;

    p:last-child {
      margin-bottom: 30px;
    }

    .rps-avatar-wrapper {
      margin: 0 auto 5px;
      width: 80px;
    }

    .group-member-username {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 15px;
      font-weight: bold;

      div.ui.label {
        position: relative;
        top: -1px;
      }

      .group-member-tagline {
        font-size: 14px;
        line-height: 16px;
        margin: 5px 0;
        color: #555;
        font-weight: normal;
        font-style: italic;
        display: flex;
        max-width: 100%;
        justify-content: center;

        .rps-icon {
          flex: 0 1 auto;
          margin-right: 5px;
          color: $blue;
          text-align: right;
        }

        > div {
          flex: 0 1 auto;
          overflow-wrap: break-word;
          max-width: 95%;
        }
      }

      .group-member-participating {
        font-size: 12px;
        line-height: 12px;
        font-weight: bold;
        display: block;
        margin: 7px auto 0;
        color: #666;

        .rps-icon {
          font-size: 13px;
          color: $blue;
          position: relative;
          top: 1px;
          margin-right: 3px;
        }

        &.is-participating .rps-icon {
          color: $green;
        }
        &.not-participating .rps-icon {
          color: $red;
        }
      }
    }

    .group-member-dates {
      margin-bottom: 10px;
      font-size: 14px;
      line-height: 18px;
      color: #777;

      > b {
        display: inline-block;
        vertical-align: bottom;
        max-width: 160px;
      }
    }

    .group-member-providers {
      margin-bottom: 10px;

      > span.authed-with {
        font-size: 14px;
        top: 0;
      }
    }

    .user-loader {
      margin-top: 15px;
    }

    .group-member-control {
      margin: 10px 0 0;
    }

    .challenge-btn {
      margin: 15px 0 0;

      .rps-icon {
        //transform: rotate(-45deg);
      }
    }
  }
}
