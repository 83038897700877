@import "../../../../styles/variables";

$avatarRadius: 35px;
$avatarRadiusSm: 25px;
$padding: 15px;
$inner: 7px;

.component-nameplate {
  position: absolute;
  left: 0;
  width: 50%;
  height: 50px;

  &.is-player {
    bottom: 0;

    .nameplate-panel {
      bottom: -19px;
    }

    .nameplate-score {
      top: 10px;
    }
  }

  &.is-opponent {
    top: 0;

    .nameplate-avatar {
      bottom: unset;
      top: 0;
    }

    .nameplate-panel {
      top: -19px;
    }

    .nameplate-score {
      bottom: 5px;
    }
  }

  &.is-third {
    left: unset;
    right: 0;
    text-align: right;

    .nameplate-avatar {
      left: unset;
      right: 0;
    }

    .nameplate-panel {
      left: unset;
      right: 77px;
    }
  }

  .nameplate-avatar {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: $avatarRadiusSm * 2;
    height: $avatarRadiusSm * 2;
    box-sizing: border-box;
    overflow: hidden;

    .rps-avatar-wrapper {
      width: 100%;
      height: 100%;
    }
  }

  .nameplate-panel {
    position: absolute;
    left: 3px;
    font-size: 14px;
    line-height: 14px;
    box-sizing: border-box;
    text-align: left;
    font-weight: bold;
  }

  .nameplate-score {
    font-family: 'Bebas Neue';
    font-size: 36px;
    line-height: 36px;
    position: absolute;
    left: $avatarRadius * 2 - 10px;
    box-sizing: border-box;
    text-align: left;
  }
}