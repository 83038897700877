@import "../../../styles/variables";

.ui.modal.modal-iosaddtohomescreen {
  &.is-ipad {
    top: 15px;
    bottom: unset;

    > div.content {
      padding-top: 10px !important;
    }

    span.arrow {
      margin-right: 10px;
    }

    p:first-child {
      margin-bottom: 0;
    }
  }

  .rps-icon {
    margin: 0 5px;
  }

  span.arrow {
    .rps-icon {
      margin: 10px 0 0;
      font-size: 24px;
    }
  }

  > div.content {
    font-size: 16px;
    line-height: 24px;
  }
}
