@import "../../styles/variables";

.joyride-btn {
  position: absolute;
  top: 7px;
  right: 14px;
  color: $blue;
  opacity: 0.8;
  background-color: white;
  height: 24px;
  border-radius: 12px;

  .rps-icon {
    font-size: 24px;
    line-height: 24px;
  }
}
