$red: #d01919;
$orange: #d8971e;
$yellow: #FFD700;
$olive: #000000;
$green: #16ab39;
$teal: #000000;
$blue: #4281a4;
$darkblue: darken($blue, 15%);
$lightblue: lighten($blue, 15%);
$paleblue: lighten($blue, 35%);
$bluebg: lighten($blue, 45%);
$violet: #000000;
$purple: #8A2BE2;
$pink: #bb00b2;
$brown: #000000;
$grey: #99a1a6;
$black: #000000;
$lightyellow: lighten($yellow, 40%);
$lightyellowbg: rgba($yellow, 0.1);

$goldtext: #e4b620;
$goldbg: #d4af3720;
$goldlight: lighten($goldtext, 10%);
$golddark: darken($goldtext, 10%);

$silvertext: rgb(82, 113, 138);
$silverbg: rgba(83, 113, 138, 0.137);
$silverlight: lighten($silvertext, 20%);
$bronzetext: #916729;
$bronzebg: #91672920;
$bronzelight: lighten($bronzetext, 10%);

$fbbluelight: #4267b2;
$fbbluedark: #385898;
$twbluelight: #00aced;
$twbluedark: #0084b4;
$googlered: #ea4335;
$microsoftblue: #0f6cbd;
$snapchatyellow: #d4d100;

$gamemoderandom: $blue;
$gamemodeinstantrps: $orange;
$gamemodetournamentrps: $orange;

$bracketborder: #666;
$brackettext: #333;
$bracketpositive: $green;
$bracketnegative: $red;

$border: #dddddd;
$subborder: #eeeeee;

$primary: #6221b1;
$primarylight: lighten($primary, 10%);
$primarydark: darken($primary, 10%);

$gametournament: #7831ac;
$gametournamentdark: darken($gametournament, 10%);
$gametournamenttext: darken($gametournament, 25%);
$gametournamentbg1: lighten($gametournament, 45%);
$gametournamentbg2: lighten($gametournament, 48%);

$gamesolo: #FA8030;
$gamesolodark: darken($gamesolo, 15%);
$gamesolotext: darken($gamesolo, 30%);
$gamesolobg1: lighten($gamesolo, 35%);
$gamesolobg2: lighten($gamesolo, 39%);

$gamefriends: #4281a4;
$gamefriendsdark: darken($gamefriends, 15%);
$gamefriendstext: darken($gamefriends, 30%);
$gamefriendsbg1: lighten($gamefriends, 41%);
$gamefriendsbg2: lighten($gamefriends, 47%);


$mobilebreakpoint: 900px;
