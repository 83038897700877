@import "./variables";

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.rps-icon {
  min-width: 12px !important;
}

body {
  background-color: #f6f6f6;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;

  /* Disallow text selection */
  user-select: none;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer and Edge */

  /* Disallow zooming */
  //touch-action: pan-x pan-y !important;
  //zoom: reset !important;
  max-height: 100vh;

  &.modal-top-aligned {
    .ui.page.modals.dimmer.transition {
      align-items: flex-start;
      flex-direction: column;

      .modal-top-aligned {
        bottom: unset;
        top: 2em;

        &:not(.fullscreen) {
          margin: unset !important;
        }
      }
    }
  }

    .ui.modal.modal-half-height {
      .scrolling.content {
        max-height: calc(50vh);
      }
    }
}

.ui.primary.button, .ui.primary.buttons .button {
	background-color: $blue;

  &:hover {
    background-color: darken($blue, 10%);
  }
}

.ui.blue.labels .label, .ui.ui.ui.blue.label {
  background-color: $blue;
  border-color: $blue;
}

.ui.dimmer.auth-dimmer {
  .rps-icon {
    font-size: 24px;
  }
}

.ui.dimmer.error-dimmer {
  .text-button {
    color: white !important;
  }
}

/* Allow text selection for input fields and textareas */
input,
textarea {
  user-select: text !important;
  -webkit-user-select: text !important; /* Safari */
  -moz-user-select: text !important; /* Firefox */
  -ms-user-select: text !important; /* Internet Explorer and Edge */
}

// html.fontawesome-i2svg-active body {
//   .rps-icon {
//     width: unset !important;
//   }
// }

.text-blue {
  color: $blue !important;
}

.text-orange {
  color: $orange !important;
}

.text-gold {
  color: $goldtext !important;
}

.text-green {
  color: $green !important;
}

.text-red {
  color: $red !important;
}

.text-bold {
  font-weight: bold !important;
}

.ellipsis-overflow {
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.ui.button:not(.only-icon) {
  .rps-icon {
    margin-right: 5px;
  }
}

.ui.button.shiny:not(.disabled) {
  &::after {
    content: "";
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    border-radius: 0.28571429rem;
    -webkit-animation: progress-active 2s ease infinite;
    animation: progress-active 2s ease infinite;
    -webkit-transform-origin: left;
    transform-origin: left;
  }
}

.ui.button.circular {
  border-radius: 10em !important;
  padding: 1em !important;

  .rps-icon {
    line-height: 17.5px;
  }

  &.tiny {
    .rps-icon {
      line-height: 10.5px;
    }
  }

  &.has-label {
    position: relative;
    padding-top: 0.65em !important;
    padding-right: 0.65em !important;

    .ui.label {
      margin-left: 5px;
    }
  }
}

.ui.label {
  &.dont-case {
    text-transform: unset !important;
  }

  &.with-icon {
    position: relative;
    padding: 0;
    font-size: 1.2rem;

    > span {
      display: inline-block;
      padding: 0.5833em 0.6833em;

      &:first-child {
        background-color: rgba(0, 0, 0, 0.2);
        padding: 0.5833em 0.5833em;
        border-top-left-radius: 0.28571429rem;
        border-bottom-left-radius: 0.28571429rem;

        .rps-icon {
          margin-right: 8px;
        }
      }

      &:last-child {
        padding-left: 0.5833em;
      }
    }
  }
}

.ui.left.popup.new-decisions-popup {
  background-color: $red;
  color: white;
  border-color: $red;
  padding: 5px 10px;
  font-weight: bold;

  &::before {
    background-color: $red;
    box-shadow: -1px -1px 0 0 $red;
    right: -5px;
    top: 11px;
  }
}

.ui.modal {
  bottom: 1em;
  width: 95% !important;
  margin: 0 !important;

  > .header {
    padding: 0.75rem 1rem !important;
  }

  > .content {
    font-size: 14px;
    padding: 2em 1em !important;

    .description {
      padding: 0 !important;
    }
  }

  &.small {
    .header:not(.ui) {
      font-size: 1.2em;
    }
  }

  > .actions {
    padding: 1rem 1rem 0 !important;

    > .ui.button,
    > .action-panel-btn-wrap {
      margin-bottom: 15px;
    }
  }

  .modal-avatar-breakout {
    margin-top: -70px;
    margin-bottom: 10px;
    display: block;
    text-align: center;
    line-height: 0;

    .modal-avatar-breakout-inner {
      display: inline-block;
      position: relative;
      border: 2px solid white;
      border-radius: 999px;

      &::after {
        content: "";
        position: absolute;
        display: block;
        top: -1px;
        bottom: -1px;
        left: -1px;
        right: -1px;
        border: 2px solid white;
        border-radius: 999px;
      }

      .rps-avatar-wrapper {
        margin: 0 !important;
      }
    }
  }
}

#root {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
}

.ScrollbarsCustom {
  position: relative;

  .ScrollbarsCustom-Wrapper {
    position: absolute;
    overflow: hidden;

    .ScrollbarsCustom-Scroller {
      position: absolute;
      inset: 0px;
      overflow: hidden scroll;

      .ScrollbarsCustom-Content {
        padding: 0.05px;
        box-sizing: border-box;
        min-height: 100%;
        min-width: 100%;
      }
    }
  }

  .ScrollbarsCustom-Track {
    position: absolute;
    overflow: hidden;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.1) none repeat scroll 0% 0%;
    width: 6px !important;
    height: calc(100% - 10px);
    top: 5px;
    right: 0px;
    opacity: 0;
    transition: opacity 0.3s linear 1s;

    .ScrollbarsCustom-Thumb {
      touch-action: none;
      cursor: pointer;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.4) none repeat scroll 0% 0%;
      width: 100%;
    }
  }

  &:hover {
    .ScrollbarsCustom-Track {
      opacity: 1;
      transition: opacity 0.3s linear 0s;
    }
  }

  &.inverted {
    .ScrollbarsCustom-Track {
      background-color: rgba(255, 255, 255, 0.1);

      .ScrollbarsCustom-Thumb {
        background-color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}

.ui.form {
  div.big-select-wrap {
    border: 1px solid rgb(221, 221, 221);
    background-color: white;
    position: relative;

    select {
      padding: 15px 15px 15px 10px;
      border-color: none;
      background-color: white;
      border-radius: 0;
      font-size: 20px;
      text-align: center;
      border: 0 solid transparent !important;
      border-right-width: 0.5em;
      border-left-width: 0.5em;
      margin: 0 10px;
      max-width: calc(100% - 20px);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &::after {
      content: '';
      position: absolute;
      right: 12px;
      top: calc(50% - 1px);
      border: solid black;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 3px;
      transform: translateY(-50%) rotate(45deg);
      -webkit-transform: translateY(-50%) rotate(45deg);
      /* Adjust size, color, position as needed */
    }
  }
}

.device-desktop-show,
.device-desktop-show-inline,
.device-tablet-show,
.device-tablet-show-inline,
.device-mobile-show,
.device-mobile-show-inline {
  display: none !important;
}

html.device-browser .device-desktop-show,
html.device-tablet .device-tablet-show,
html.device-mobile .device-mobile-show {
  display: block !important;
}

html.device-browser .device-desktop-show-inline,
html.device-tablet .device-tablet-show-inline,
html.device-mobile .device-mobile-show-inline {
  display: inline !important;
}

html.device-browser .device-desktop-hide,
html.device-tablet .device-tablet-hide,
html.device-mobile .device-mobile-hide {
  display: none !important;
}

/* Semantic responsive overrides (forces mobile) */
.ui.ui.ui.container:not(.fluid) {
  width: auto !important;
  margin-left: 1em !important;
  margin-right: 1em !important;
}
/* */

@media screen and (max-width: 915px) and (orientation: landscape) {
  html.device-mobile {
    /* Rotate the content container */
    transform: rotate(-90deg);
    transform-origin: left top;
    /* Set content width to viewport height */
    width: 100vh;
    /* Set content height to viewport width */
    height: 100vw;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;

    body {
      /* Set content width to viewport height */
      width: 100vh !important;
      /* Set content height to viewport width */
      height: 100vw !important;
      max-width: 100vh !important;
      max-height: 100vw !important;
    }
  }
}
