.random-game-draw-transition {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  padding-bottom: 50px;

  .number {
    animation: cdBeat 750ms ease-in-out 750ms infinite;
    font-weight: bold;
    font-size: 7rem;
  }
}

@keyframes cdBeat {
  0%,
  40% {
    transform: none;
  }
  10% {
    transform: scale(1.25);
  }
}
