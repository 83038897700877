@import "../../../styles/_variables.scss";

.ui.modal.modal-group-name {
  > div.content {
    text-align: left;

    .tournament-manage-title {
      margin: 0 0 5px;
      display: flex;
      width: 100%;

      h3 {
        flex: 0 1 auto;
        margin: 0;
        font-size: 18px;
        line-height: 18px;
        max-width: 100%;
      }

      > span {
        display: block;
        flex: 0 1 auto;
        font-size: 18px;
        line-height: 18px;
        margin-right: 5px;
      }
    }

    .tournament-manage-controls {
      display: block;
      border-top: 1px solid #ddd;
      margin-top: 20px;
      padding-top: 20px;
      padding-left: 5px;
      margin-bottom: 15px;
      text-align: left;

      .tournament-manage-button-group {
        padding: 10px 0 5px;
        display: flex;

        .ui.button {
          flex: 1 0 50%;
        }
      }

      .tr-value {
        display: flex;
        width: 100%;
        margin-bottom: 10px;

        .big-select-wrap {
          flex: 1 0 auto;
          margin: 0 !important;
        }

        .ui.button {
          flex: 0 1 150px;
          margin: 0;

          .rps-icon {
            padding-top: 19px;
          }
        }
      }
    }

    .ui.form {
      margin: 0 auto;
      max-width: 400px;
      font-size: 18px;

      .group-name-field {
        position: relative;

        .component-emoji-picker {
          position: absolute;
          bottom: 10px;
          right: 8px;
        }
      }

      .big-select-wrap {
        margin-bottom: 10px;
      }

      .decision-reschedule-field {
        > label {
          margin-top: 20px;
          font-size: 14px;
          text-align: left;
        }
      }

      .time-validation {
        font-size: 14px;
        margin-bottom: 10px;
      }
    }

    .group-name-title {
      margin-top: -5px;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 5px;
      font-weight: bold;
      text-align: center;
      width: 100%;
      max-width: 100%;
    }

    .group-boss {
      margin-bottom: 5px;
      font-weight: bold;

      .rps-icon {
        color: $yellow;
        margin-right: 3px;
      }
    }

    .group-created {
      color: #666;
      margin-bottom: 15px;
    }

    .group-boss,
    .group-name-title,
    .group-created {
      text-align: center;
    }

    .input-rename-group {
      margin-bottom: 15px;

      .ui.action.input {
        display: flex;
        width: 100%;

        > div {
          flex: 1 0 auto;

          input {
            width: 100%;
          }
        }

        > button {
          flex: 0 1 auto;
        }
      }
    }

    .ui.form {
      margin: 0 auto;
      max-width: 400px;
      font-size: 16px;

      label {
        margin: 0 0 5px;
        display: block;
        font-weight: bold;
        font-size: 14px;
      }

      input {
        border-radius: 0 !important;
      }
    }

    .leave-btn-wrap {
      text-align: left;
      font-size: 14px;
      position: relative;
      left: -1px;

      .leave-btn {
        margin-right: 10px;
        color: #444;

        .rps-icon {
          margin-right: 5px;
          top: 1px;
          position: relative;
        }
      }

      &+.leave-btn-wrap {
        margin-top: 10px;
      }
    }
  }

  .close-btn,
  .cancel-btn {
    margin-left: 0 !important;
  }
}
