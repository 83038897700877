@import "../../styles/_variables.scss";

.user-slideout-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 17%;
  right: 0;
  z-index: 0;
  display: flex;
  flex-direction: column;

  .decision-participants {
    height: 100%;
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.tournament-slideout-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 17%;
  left: 0;
  z-index: 0;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

  > div {
    padding: 15px;
    display: flex;
    flex-direction: column;

    .ts-header {
      flex: 0 1 auto;
      margin-bottom: 10px;
      text-align: center;

      h1 {
        margin-bottom: 0;
        font-size: 28px;
        font-weight: bold;
        color: $blue;
      }

      h3 {
        margin: 0;
      }
    }

    .ts-footer {
      margin: 20px 0 0;
      text-align: center;
    }

    .ts-rounds {
      margin: 20px 0 0;

      .ts-round-table {
        text-align: left;
        margin: 0 auto;
        border-collapse: collapse;
        font-family: Arial, sans-serif;
        font-size: 14px;

        th,
        td {
          padding: 2px 8px;
          border: none;

          .rps-icon {
            margin: 0 3px 0 0;
          }
        }

        td.tst-round-status {
          text-align: left;
        }

        .tst-round-num {
          text-align: right;
          font-weight: bold;
        }

        .planned-round {
          color: #999;
        }

        .complete-round {
          color: $green;
        }

        .active-round {
          color: $blue;
        }

        .skipped-round {
          color: #999;
          text-decoration: line-through;
        }
      }
    }

    .ts-body {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      text-align: center;

      h5 {
        font-size: 20px;
        line-height: 20px;
        margin-bottom: 25px;
      }

      .ts-infos {
        flex: 0 1 auto;
        display: flex;
        text-align: center;
        padding: 0 0 25px;

        .ts-info {
          flex: 1 0 33.3%;

          .ts-info-num {
            display: block;
            font-size: 24px;
            font-weight: bold;
            margin: 0 0 10px;
            line-height: 20px;

            span {
              font-size: 14px;
              font-weight: normal;
            }
          }

          .ts-info-text {
            font-size: 12px;
            line-height: 1.1;
          }
        }
      }

      .ts-brackets {
        flex: 0 1 auto;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;

        h5 {
          margin-top: 25px;
        }

        > div {
          padding: 5px 20px 15px 20px;
        }
      }
    }
  }
}

.subpage-group {
  display: flex;
  min-height: 100%;
  background-color: #f6f6f6;
  z-index: 1;
  margin-left: -1px;
  margin-right: -1px;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  //box-shadow: 8px 0 3px -5px rgba(0, 0, 0, 0.2);

  /*&::after {
    content: "";
    position: absolute;
    top: 0;
    right: -10px;
    bottom: 0;
    width: 10px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0));
    z-index: -1;
  }*/

  &.slideout-not-dragging {
    transition: transform 0.3s ease;
  }

  &.slideout-open {
    //transform: translateX(-83%);

    .slideout-close-overlay {
      display: block;
    }
  }

  .slideout-close-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: none;
    background-color: rgba(255, 255, 255, 0.4);
  }

  &#subpage-create {
    h1,
    h3 {
      text-align: center;
    }
  }

  &.deciding-visible {
    #subpage-content {
      div.inner-content {
        width: 100%;
        max-width: 100%;
        padding: 0;
        min-height: 100%;
      }
    }
  }

  &.preparing-visible {
    #subpage-content {
      div.inner-content {
        width: 100%;
        max-width: 100%;
        padding: 0 25px;
        min-height: 100%;
      }
    }
  }

  .join-wrapper {
    position: relative;
    margin-bottom: 5px;

    .ui.button {
      display: block;
      width: 90%;
      margin: 0 auto;
    }
  }

  .provisional-result {
    display: inline-block;

    .ui.label {
      position: relative;
      top: -1px;
      margin: 10px 5px 0;
      padding-right: 24px;
      padding: 5px 27px 5px 8px;
      font-size: 14px;
      line-height: 15px;

      .rps-icon {
        font-size: 15px;
        color: white;
        position: absolute;
        right: 7px;
        top: 5px;
      }
    }
  }

  #subpage-content {
    width: 100%;
    flex-grow: 1;
    position: relative;

    .ui.container {
      display: flex;
      min-height: 100%;
      background-color: #f6f6f6;
    }

    div.inner-content {
      max-width: 850px;
      margin: 0 auto;
      padding: 0 0 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;

      .group-upper {
        flex: 0 0 276px;
        display: flex;
        flex-direction: column;
        position: relative;

        .group-upper-slideout-buttons {
          display: flex;
        }

        .tournament-prizes-button {
          padding: 10px 21px 0 13px;
          margin: 4px 0 0;
          text-align: left;
          flex: 1 0 25%;
          position: relative;

          .tournament-prizes-button-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            .nav-chevron {
              flex: 0 0 15px;
              font-size: 18px;
              color: $blue;
              text-align: left;
            }

            .tournament-prizes-button-content {
              flex: 0 0 auto;
              font-size: 24px;
              color: #444;
              text-align: left;
              margin-left: 5px;
              border: 1px solid #666;
              border-radius: 40px;
              width: 41px;
              height: 41px;
              margin-top: 2px;
              display: flex;
              align-items: center;
              justify-content: center;

              .rps-icon {
                position: relative;
                top: 0;
                left: -1px;
              }
            }
          }

          .tournament-prizes-button-label {
            font-size: 12px;
            width: 100%;
            opacity: 0.8;
            position: absolute;
            bottom: -18px;
            left: 23px;
          }
        }

        .group-current-tournament {
          padding: 0px 20px;
          height: 119px;
          margin: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          &.no-active-tournament {
            h5 {
              font-size: 16px;
            }

            .winners-to-draw {
              font-size: 16px;
            }
          }

          h5 {
            flex: 0 0 auto;
            margin: 0;
          }

          h1 {
            flex: 0 0 auto;
            margin: 0 0 5px 0;
            font-size: 30px;
            font-weight: bold;
            width: 100%;

            & + span.winners-to-draw {
              margin-bottom: 10px;
            }
          }

          h3 {
            margin: 16px 0 2px;
            font-weight: normal;
            font-size: 16px;
            line-height: 16px;
            color: #444;
          }

          span.winners-to-draw {
            color: #555;
            font-size: 13px;
            line-height: 13px;
            display: block;
            flex: 0 0 auto;
            margin: 0 0 0 0;
          }

          .current-tournament-status {
            font-size: 13px;
            line-height: 13px;
            color: $blue;

            .rps-icon {
              margin-right: 4px;
              position: relative;
              top: 0px;
            }
          }
        }

        .group-dynamic-members {
          padding: 10px 14px 0 13px;
          margin: 4px 0 0;
          text-align: right;
          flex: 1 0 75%;
        }

        .group-nav-buttons {
          width: 100%;
          height: 45px;
          padding: 0 10px 5px 0;
          text-align: right;
          border-bottom: 1px solid #ddd;

          .group-nav-label {
            display: inline-block;
            inset: 0 auto auto 0;
            z-index: 1000;
            transform: translate(-2px, -1px);
          }

          .group-nav-button {
            height: 45px;
            min-width: 39px;
            margin-left: 5px;
            position: relative;
            display: inline-block;

            .ui.button.circular {
              height: 39px;
              min-width: 39px;
              font-size: 18px;
              padding: 0 !important;
              border-color: transparent;
              box-shadow: none;
              margin: 0;
            }

            .button-description {
              position: absolute;
              left: 0;
              right: 5px;
              bottom: 7px;
              font-size: 11px;
              font-weight: bold;
              text-align: center;
              display: none;
            }
          }
        }

        .decision-participants {
          flex: 1 1 auto;
          border-bottom: 1px solid #ddd;
          overflow-y: auto;
          overflow-x: hidden;
        }

        .group-tabs {
          flex: 0 0 46px;
          display: flex;
        }
      }

      .group-lower {
        flex: 1 0 calc(100% - 276px);
        display: flex;
        flex-direction: column;
        overflow: hidden auto !important;
        position: relative;

        .ui.button.slideout-button {
          margin-top: 10px;
        }

        .hidden-share-button {
          display: none !important;
        }

        > .decision-info {
          display: flex;
          flex-direction: column;

          > div {
            margin: auto;
            width: 100%;
          }
        }

        .tournament-config,
        .repeating-decisions,
        .decision-info,
        .decision-results-chosen {
          flex: 1 1 auto;
          overflow: hidden auto !important;

          div.no-decisions {
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            padding: 2em 1em;
          }

          div.new-group-instructions {
            font-size: 20px;
            line-height: 30px;
            text-align: center;
            padding: 3em 1em;
          }
        }

        .decision-results-chosen {
          overflow-x: hidden;

          .group-members {
            padding-top: 0;
          }
        }

        .action-panel {
          flex: 0 1 auto;
        }
      }

      .repeating-config-wrapper {
        position: relative;
        flex: 1 0 100%;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .repeating-config-cancel {
          position: absolute;
          top: 23px;
          right: 20px;
          font-size: 20px;
          cursor: pointer;
          z-index: 999;
          border: 1px solid $red;
          border-radius: 50px;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          .rps-icon {
            flex: 0 0 auto;
            color: $red;
          }
        }

        .decision-info {
          h5.game-owner {
            margin-bottom: 10px;
          }

          h1 {
            margin-top: 0px;
            margin-bottom: 20px;
            line-height: 37px;
          }
        }
      }

      .tournament-starting {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;

        h5 {
          flex: 0 0 auto;
          margin: 10px 0;
          font-size: 16px;
          line-height: 16px;
          font-weight: normal;
        }

        .rps-icon {
          flex: 0 0 auto;
          margin: 10px 0;
          font-size: 32px;
          line-height: 32px;
          font-weight: normal;
        }
      }

      .decision-info {
        text-align: center;
        padding: 25px 25px 15px;

        h3 {
          margin: 0 0 35px;
        }

        h1 {
          font-size: 32px;
          line-height: 32px;
          font-weight: bold;
          margin: 0 0 15px;
        }

        h5 {
          margin: 10px 0 5px;

          .rps-avatar-wrapper {
            width: 24px;
            height: 24px;
            margin-right: 6px;
            position: relative;
          }

          .player-count {
            display: block;
            font-size: 13px;
            color: #666;
            font-weight: normal;
          }

          &.game-owner {
            margin: 10px 0 15px;
            line-height: 24px;
            font-size: 14px;
            font-weight: normal;
            color: #666;
            text-align: center;
          }
        }

        h6 {
          font-size: 15px;
          padding: 1em;
          margin: 0 0 25px;
          color: $blue;
          border: 2px solid $blue;
          font-weight: bold;

          .rps-icon {
            display: block;
            margin: 0 auto 10px;
            font-size: 28px;
            line-height: 28px;
          }
        }

        .decision-rescheduled {
          font-size: 12px;
          color: $red;
          margin-top: 0px;
        }

        .progress-tournament-rules {
          margin-bottom: 10px;
        }

        .decision-joined {
          margin-bottom: 10px;
          color: $green;

          &.ready-to-play {
            color: $green;
          }

          &.not-enough-players {
            color: $red;
          }

          &.waiting-players {
            color: $blue;
          }
        }

        .timer-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: -5px;

          .centering-placeholder {
            flex: 0 0 32px;
          }

          .component-timer {
            margin-top: 10px;
            flex: 0 1 auto;
          }

          &.timer-repeating {
            margin: 15px 0 10px;
            .component-timer {
              flex-basis: 240px;
            }

            .ui.basic.button.circular {
              top: -8px;
            }
          }

          .ui.basic.button.circular {
            flex: 0 1 32px;
            margin-left: 0;
            padding: 10px !important;
            position: relative;
            top: -4px;

            .rps-icon {
              margin-right: 0;
            }
          }
        }

        .instant-rps-move-set {
          padding-bottom: 20px;

          > span {
            font-weight: bold;
            display: inline-block;
            margin: 0 0 5px 0;
          }

          .instant-rps-moves {
            display: flex;
            justify-content: center;

            .instant-rps-move {
              flex: 0 1 auto;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 3px;
              font-size: 18px;
              border: 1px solid $blue;
              color: $blue;
              width: 34px;
              height: 34px;
              border-radius: 20px;

              .rps-icon {
                flex: 0 0 auto;
              }

              &:last-child {
                border-color: $orange;
                color: $orange;
              }
            }
          }
        }

        &.decision-info-tournament {
          display: flex;
          flex-direction: column;
          padding-top: 15px;
          padding-bottom: 15px;
          position: relative;

          .tournament-manage {
            position: absolute;
            top: 15px;
            right: 15px;

            .ui.basic.button {
              margin: 0;
              width: 36px;
            }
          }

          .decision-info-wrapper {
            flex: 1 1 auto;

            .tournament-player-summary {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin: 25px 0 0;

              .tournament-player-summary-item {
                flex: 0 0 25%;

                .tpsi-value {
                  font-size: 24px;
                  font-weight: bold;
                  margin: 0 0 5px;
                }

                .tpsi-label {
                  font-size: 12px;
                  color: #666;
                  margin: 0;
                }
              }
            }
          }
        }

        .tournament-info {
          padding: 0;
          flex: 1 0 auto;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          > div {
            flex: 0 0 auto;
          }

          .game-mode-icons {
            margin-bottom: 25px;
          }

          .tournament-round {
            width: 100%;

            .tournament-round-title {
              font-weight: bold;
              font-size: 22px;
              margin: 0 0 15px;
            }

            .ui.progress {
              margin: 40px 0 15px;

              .bar {
                height: 24px;
                min-width: 90px;

                .progress {
                  text-shadow:
                    1px 1px 1px rgba(0, 0, 0, 0.1),
                    -1px -1px 1px rgba(0, 0, 0, 0.1),
                    -1px 1px 1px rgba(0, 0, 0, 0.1),
                    1px -1px 1px rgba(0, 0, 0, 0.1);
                  color: white;
                  letter-spacing: 2px;
                  top: 50%;
                  font-size: 13px;

                  &::after {
                    content: " matches";
                    letter-spacing: 0px;
                  }
                }
              }

              .label {
                font-size: 14px;
                top: unset;
                bottom: calc(100% + 5px);
                color: #444;

                > .rps-icon {
                  position: relative;
                  top: 1px;
                  left: 2px;
                }

                .progress-tournament-rules {
                  font-weight: normal;
                  opacity: 0.8;
                  font-size: 13px;
                  margin-top: 2px;

                  .rps-icon {
                    margin-left: 10px;
                    margin-right: 3px;

                    &:first-child {
                      margin-left: 0;
                    }
                  }
                }
              }
            }
          }

          div.new-group-instructions {
            padding-top: 0;

            .enable-notifications-prompt-btn {
              margin-top: 30px;
            }
          }

          .match-status {
            margin-bottom: 40px;

            .component-timer {
              margin-bottom: 0;
            }

            .match-status-copy {
              .match-completed {
                font-weight: bold;
                display: block;
                margin-bottom: 5px;

                .match-completed-heading {
                  font-size: 20px;
                  line-height: 20px;
                }
              }
            }
          }

          .match-whats-next {
            .match-whats-next-1 {
              margin-top: 10px;
              display: block;
              font-size: 16px;
              line-height: 14px;
            }

            .match-whats-next-2 {
              margin-top: 20px;
              display: block;
              font-size: 14px;
              line-height: 20px;
            }
          }

          .my-match {
            &.my-match-positive {
              .match-avatars {
                .match-avatar {
                  border-radius: 999px;

                  &.match-avatar-loser {
                    opacity: 0.6;
                  }

                  &.match-avatar-winner {
                    .rps-avatar-wrapper {
                      border: 3px solid $green;
                      position: relative;
                      border-radius: 999px;
                    }

                    .match-account-username {
                      color: $green;
                    }
                  }
                }
              }
            }

            &.my-match-negative {
              .match-avatars {
                .match-avatar {
                  border-radius: 999px;

                  &.match-avatar-winner {
                    opacity: 0.6;
                  }

                  &.match-avatar-loser {
                    .rps-avatar-wrapper {
                      border: 3px solid $red;
                      position: relative;
                      border-radius: 999px;
                    }

                    .match-account-username {
                      color: $red;
                    }
                  }
                }
              }
            }

            .match-avatars {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              margin: 10px 0 10px;

              .match-avatar {
                flex: 0 0 80px;
                width: 80px;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow: visible;
                position: relative;
                margin-right: 8px;

                &.match-avatar-right {
                  margin-left: 8px;
                }

                .rps-avatar-wrapper {
                  flex: 0 0 auto;
                  margin: 0 0 5px;
                  width: 80px;
                  height: 80px;
                }

                .match-account-username {
                  flex: 0 0 auto;
                  font-weight: bold;
                  font-size: 16px;
                }
              }

              .match-account-score {
                flex: 0 1 auto;
                font-family: "Bebas Neue";
                font-size: 36px;
                position: relative;
                top: -7px;
              }

              .match-vs {
                flex: 0 1 auto;
                font-weight: bold;
                font-size: 14px;
                margin: 0 15px;
                color: #666;
                position: relative;
                top: -11px;
              }
            }

            .match-advancer {
              margin: 20px 0 0;
            }
          }
        }
      }

      .component-decision-results {
        .decision-results {
          padding: 0;
          height: 100%;
          display: flex;
          flex-direction: column;

          .decision-results-header {
            flex: 0 1 auto;
            padding: 30px 20px;
            background-color: $lightblue;

            h3 {
              margin-bottom: 7px;
              color: white;

              &.drh-game-mode {
                font-weight: bold;
                font-size: 32px;
                line-height: 32px;
              }
            }

            h5.decision-results-played-for {
              color: white;
              font-weight: normal;
              font-size: 14px;
              line-height: 14px;
              margin: 30px 0 0;
              opacity: 0.9;
            }

            h5.decision-results-time {
              margin: 7px 0 0;
              color: white;
              opacity: 0.6;
              font-size: 13px;
              line-height: 16px;
            }

            .decision-results-stake {
              color: white;
            }

            > .rps-icon {
              font-size: 36px;
              line-height: 36px;
              margin: 10px 0 10px;
              height: 36.5px;
            }
          }

          .decision-results-chosen-section {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 0 20px;

            h3 {
              margin: 0;
              font-size: 18px;
            }

            > .rps-icon {
              color: $red;
              font-size: 32px;
              line-height: 32px;
            }
          }

          h3 {
            font-size: 20px;
            margin: 0 0 25px;
          }

          h3 + h5 {
            font-weight: normal;
          }

          h5 {
            margin: 5px 0;

            .rps-avatar-wrapper {
              position: relative;
              top: 0px;
            }

            &.decision-results-time {
              color: #666;
              font-weight: normal;
              font-size: 13px;
              margin-top: 10px;
            }
          }

          .decision-results-stake {
            margin: 0;
            font-size: 32px;
          }

          ul {
            list-style: none outside none;
            padding: 0;

            li {
              font-size: 20px;
              margin: 0 0 5px;
            }
          }
        }

        .group-lower {
          h2 {
            margin: 0;
            padding: 10px 13px 10px;
            font-size: 18px;
            border-bottom: 1px solid #ddd;
          }
        }
      }

      .create-header {
        flex: 0 1 30%;
        border-bottom: 1px solid #ddd;
        padding-top: 70px;
        position: relative;

        h1 {
          color: $blue;
          font-size: 42px;
          font-weight: normal;
          margin: 0 0 5px;
        }

        h3 {
          font-size: 18px;
          margin: 0 0 15px;
        }

        .create-links {
          text-align: center;
          margin: 0;
          font-size: 16px;
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 0 0 15px;
          opacity: 0.6;

          a {
            display: inline-block;
            margin: 0 5px;
          }
        }
      }

      .notification-permissions {
        text-align: center;
        margin: 3px 0 13px;
        font-size: 12px;
        color: #333;
      }

      .repeating-decisions {
        flex: 1 1 40%;
        overflow-y: auto;
      }

      .group-tabs {
        background-color: #eee;

        .group-tab {
          flex: 0 0 50%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-bottom: 1px solid #ddd;
          text-align: center;
          line-height: 14px;
          color: #666;
          cursor: pointer;
          font-size: 14px;
          border-right: 1px solid #ddd;

          .group-tab-heading {
            flex: 0 0 auto;
            width: 100%;
          }

          .group-tab-status {
            flex: 0 0 auto;
            width: 100%;
            font-size: 12px;
            color: $blue;
            margin-top: 6px;
          }

          .ui.label {
            margin-left: 5px;
            font-size: 10px;
            padding: 0.5833em 0.833em 0.5833em 0.7em;
            position: relative;
            top: -1px;
          }

          .rps-icon {
            position: relative;
            top: 1px;
            margin-right: 3px;
            min-width: 12.25px;
          }

          &:last-child {
            border-right: none;
          }

          &.active {
            border-bottom: 0;
            background-color: #f6f6f6;
            color: #333;
          }
        }
      }

      .action-panel {
        position: static !important;
        display: block !important;
        flex: 0 1 auto;

        .ui.equal.width.grid {
          > .column {
            padding-left: 0.5em;
            padding-right: 0.5em;

            &:first-child {
              padding-left: 1em;
            }

            &:last-child {
              padding-right: 1em;
            }
          }
        }

        .ui.button {
          .rps-icon {
            margin: 0 0 0 10px;
            position: relative;
            top: 1px;
          }

          &.icon-top {
            .rps-icon {
              display: block;
              margin: 0 auto 8px;
              font-size: 20px;
              top: 0;
            }
          }
        }
      }
    }
  }

  #group-nav {
    padding: 0;
    flex: 0 0 280px;
    border-left: 1px solid #ccc;
    background-color: #fefefe;

    > div {
      width: 280px;
      height: 100%;
      box-sizing: border-box;
      padding: 0;
      display: flex;
      flex-direction: column;

      > div {
        padding: 10px;
        flex: 0 1 auto;
      }
    }
  }

  div.ui.horizontal.divider {
    margin: 3rem 0 2rem;

    &:first-child {
      margin-top: 0;
    }
  }

  .previous-tournaments {
    margin-top: 40px;
  }

  .waiting-to-start,
  .not-in-tournament,
  .promo-waiting {
    text-align: center;
    margin: 60px 0 80px;

    h5.ui.header {
      margin-top: 10px;
      font-weight: normal;
    }
  }

  .promo-waiting {
    margin: 0;
    height: 100%;
    box-sizing: border-box;
    padding: 30px 0 0;

    .promo-heading {
      line-height: 50px;

      img {
        width: 50px;
        height: 50px;
        margin: 0 15px 0 0;
        vertical-align: middle;
        position: relative;
        top: -2px;
      }
    }

    .group-spots {
      margin: 40px 0 40px;

      > div {
        font-size: 24px;
        line-height: 26px;
        margin: 0 0 5px;
      }
    }

    .group-timer {
      margin: 40px 0 40px;

      .rps-countdown .flip-countdown {
        text-align: center;
      }

      > div {
        margin: 15px 0 0;
      }
    }

    .promo-heading {
      font-family: "Bebas Neue";
      text-transform: uppercase;
      font-size: 48px;
      line-height: 48px;
      font-weight: normal;
    }

    .promo-leave {
      margin: 15px 0;

      span {
        font-size: 12px;
        color: $red;
      }
    }

    .promo-howto {
      margin-top: 30px;
      padding: 20px;
    }
  }

  .group-info {
    margin-top: 60px;
    margin-bottom: 20px;
  }

  .group-status-message {
    text-align: center;
    padding-top: 80px;

    .rps-icon {
      display: block;
      font-size: 64px;
    }
  }

  .client-footer {
    width: 100%;
    position: fixed;
    padding-top: 13px;
    text-align: center;
    bottom: 0;

    &.client-footer-entries-closed {
      .start-date {
        cursor: pointer;
      }
    }

    .rps-logo {
      position: absolute;
      bottom: 10px;
      left: 10px;
      opacity: 0.7;

      a {
        display: inline-block;
        margin: 0 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    .start-date {
      display: inline-block;
      font-family: "Bebas Neue";
      font-size: 36px;
      line-height: 36px;
      font-weight: normal;
    }

    .reminder-bell {
      .notification-btn {
        top: -9px;
        left: 15px;
        font-size: 24px;
        line-height: 24px;

        .rps-icon {
          color: white;
        }
      }
    }

    .end-date {
      margin-top: -2px;
      margin-bottom: 11px;
      font-size: 12px;
      line-height: 12px;
      opacity: 0.8;
    }

    .final-buttons {
      position: absolute;
      bottom: 10px;
      right: 10px;

      .rps-icon,
      span.text-button {
        color: #ddd;
      }

      span.text-button:hover {
        color: #bbb;

        .rps-icon {
          color: #bbb;
        }
      }
    }
  }
}

.subpage-group-solo {
  display: flex;
  min-height: 100%;
  background-color: #f6f6f6;
  flex-direction: column;

  #subpage-content {
    display: flex;
    flex: 1 0 100%;
    flex-direction: column;

    .inner-content {
      display: flex;
      flex: 1 0 100%;
      flex-direction: column;

      .solo-upper {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .solo-header {
          text-align: center;

          h1 {
            margin: 0 0 30px;
          }
        }

        .solo-stats {
          text-align: center;
          margin: 0;

          h3 {
          }

          .solo-stats-items {
            display: flex;
            flex: 0 1 auto;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;

            > div {
              flex: 0 0 100px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 0 10px;

              .solo-stats-name {
                flex: 0 1 auto;
                font-size: 14px;
                line-height: 14px;
                font-weight: bold;
                margin-bottom: 5px;
              }

              .solo-stats-value {
                flex: 0 1 auto;
                font-family: "Bebas Neue";
                font-size: 26px;
                line-height: 26px;
              }
            }
          }
        }

        .solo-vs {
          display: flex;
          flex: 0 1 auto;
          align-items: center;
          justify-content: center;
          margin: 20px 0;

          .solo-vs-avatar {
            flex: 0 1 auto;
            text-align: center;
            margin: 0 8px;

            .rps-avatar-wrapper {
              width: 80px;
              height: 80px;
              margin: 0;
              position: relative;
            }

            .solo-vs-name {
              margin: 12px 0 0;
              font-size: 16px;
              line-height: 16px;
              font-weight: bold;
            }
          }

          .solo-vs-score {
            flex: 0 1 auto;
            font-family: "Bebas Neue";
            font-size: 36px;
            position: relative;
            top: -9px;
          }

          .solo-vs-vs {
            font-weight: bold;
            font-size: 14px;
            margin: 0 15px;
            color: #666;
            padding-bottom: 28px;
          }
        }

        .solo-match-status {
          font-size: 20px;
          line-height: 20px;
          text-align: center;
          margin: 25px 0 15px;
        }

        .solo-copy {
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          margin: 15px 0 40px;
          padding: 0 20px;
        }

        .solo-options {
          .field {
            margin-bottom: 30px;
            text-align: center;

            label {
              span {
                color: #444;
                font-size: 14px;
                position: relative;
                top: 0;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            .decision-form-description {
              font-size: 14px;
              line-height: 18px;
              color: #666;
              text-align: center;
              padding: 0 10px;
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }

              .popup-trigger {
                color: $blue;
                font-weight: bold;
              }
            }

            input[type="number"],
            .number-dropdown {
              width: 100px;
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}

.subpage-group-promo {
  display: flex;
  min-height: 100%;
  background-color: #f6f6f6;
  flex-direction: column;

  #subpage-content {
    display: flex;
    flex: 1 0 100%;
    flex-direction: column;

    .inner-content {
      display: flex;
      flex: 1 0 100%;
      flex-direction: column;

      .promo-upper {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .promo-header {
          text-align: center;

          h1 {
            margin: 0 0 30px;
          }
        }

        .promo-match-status {
          font-size: 20px;
          line-height: 20px;
          text-align: center;
          margin: 40px 0 15px;
        }

        .promo-copy {
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          margin: 15px 0 40px;
          padding: 0 20px;
        }

        .promo-options {
          .field {
            margin-bottom: 30px;
            text-align: center;

            label {
              span {
                color: #444;
                font-size: 14px;
                position: relative;
                top: 0;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            .decision-form-description {
              font-size: 14px;
              line-height: 18px;
              color: #666;
              text-align: center;
              padding: 0 10px;
              margin-bottom: 10px;

              &:last-child {
                margin-bottom: 0;
              }

              .popup-trigger {
                color: $blue;
                font-weight: bold;
              }
            }

            input[type="number"],
            .number-dropdown {
              width: 100px;
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}

.page-theme-tournament {
  .progress-tournament-rules {
    margin-top: 25px;
    font-size: 15px;
    line-height: 15px;

    > div {
      display: block;
      margin-bottom: 10px;
    }
  }

  .subpage-group #subpage-content div.inner-content {
    .group-upper {      
      border-bottom: 1px solid #ddd;
      justify-content: center;

      .group-current-tournament-promo {
        h3 {
          margin-top: 0;
          margin-bottom: 5px;
        }        
      }
    }
    
    .decision-info {
      .promo-player-count {
        margin-bottom: 35px;

        h5 {
          font-size: 24px;
          line-height: 24px;

          span.player-count {
            margin-top: 7px;
          }
        }
      }

      h5 {
        font-size: 16px;
        line-height: 16px;
      }
    }

    .decision-joined {
      margin-top: 30px;
      font-size: 16px;
      line-height: 16px;
    }
  }
}
