#dev-tools {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1800;
  background: rgba(0,0,0,0.2);

  > div {
    opacity: 1;
    position: relative;
    padding: 5px;
    text-align: center;
    pointer-events: all;
    font-size: 11px;
    background-color: rgba(255,255,255,0.5);
  }

  .ui.button {
    margin: 5px;
    display: block;
    width: 210px;
    text-align: center;
  }
}