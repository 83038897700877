.number-dropdown {
  width: 100px;
  display: inline-block;

  div.select-wrap {
    border: 1px solid rgb(221, 221, 221);
    background-color: white;
    border-radius: 4px;

    select {
      padding: 10px 0 10px 10px;
      border-color: none;
      background-color: white;
      border-radius: 0;
      font-size: 16px;
      text-align: left;
      border: 0 solid transparent !important;
      border-right-width: 0.5em;
      border-left-width: 0.5em;
      margin: 0 15px;
      max-width: calc(100% - 20px);
    }
  }
}
