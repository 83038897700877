#app #main #page-logged-in {
  display: flex;
  flex-direction: column;
  padding: 0;
  flex: 1 1 auto;
  position: relative;

  #page-content {
    display: flex;
    flex-direction: column;
    flex: 1 1 100%;
    padding: 0;
    overflow: hidden;
  }
}
