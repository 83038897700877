@import "../../styles/variables";

.component-decisions-repeating {
  width: 100%;

  h3 {
    margin: 0 0 30px;
  }

  div.no-decisions {
    font-size: 16px;
    text-align: center;
  }

  > div {
    .decisions-repeating-item {
      width: 100%;
      box-sizing: border-box;
      margin-bottom: 3px;
      padding: 5px 15px 10px;
      border-bottom: 1px solid #ccc;
      font-size: 15px;
      line-height: 28px;
      cursor: pointer;

      &:first-child {
        padding-top: 15px;
      }

      &:last-child {
        //border-bottom: 0;
      }

      &.open {
        background-color: $bluebg;
      }

      .repeating-controls {
        display: block;

        .repeating-button-group {
          padding: 10px 0 0;
          display: flex;

          &:first-child {
            margin-bottom: -6px;
          }

          // &.three-buttons {
          //   .rps-icon {
          //     display: none;
          //   }
          // }

          .ui.button {
            flex: 1 1 50%;
            padding-left: 5px !important;
            padding-right: 5px !important;
          }
        }
      }

      > div {
        display: flex;
        margin: 0 0 5px;

        > div.creator {
          line-height: 21px;
          flex: 0 0 50%;
          text-align: left;

          > span {
            display: inline-block;
          }

          .ellipsis-overflow {
            max-width: 95px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            top: -2px;
          }

          .rps-avatar-wrapper {
            display: inline-block;
            width: 25px;
            height: 25px;
            vertical-align: middle;
            margin-right: 5px;
            position: relative;
            top: -1px;
          }
        }

        > span {
          display: block;
          width: 50%;
          line-height: 21px;

          .rps-icon {
            margin-right: 3px;
          }

          &.end-time {
            flex: 0 0 auto;
            color: #888;
            text-align: right;
          }

          &.stake {
            flex: 0 0 45%;
            font-weight: bold;
            text-align: left;
          }

          &.repeating-schedule {
            flex: 0 0 55%;
            font-size: 13px;
            line-height: 16px;
            text-align: right;
          }

          &.participating {
            flex: 0 0 auto;
            text-align: right;
            font-weight: bold;

            &.am-participating {
              color: $green;
            }

            &.not-participating {
              color: $blue;
            }

            .rps-icon {
              margin-right: 3px;
            }
          }

          &.participants {
            text-align: left;
          }

          &.chosen-one {
            flex: 0 0 auto;
            text-align: left;

            .text-button {
              cursor: pointer;
              color: $blue;
            }

            .rps-avatar-wrapper {
              width: 25px;
              height: 25px;
              vertical-align: middle;
              margin-left: 5px;
            }
          }

          &.chosen-ones {
            flex: 0 0 auto;
            text-align: center;
            width: 100%;
            padding-top: 5px;

            > span {
              display: inline-block;
              margin: 0 5px;
            }
          }
        }
      }
    }
  }
}
