@import "../../styles/variables";

#brackets {
  color: $brackettext;

  h4 {
    color: $brackettext;
  }

  .br-rows {
    .br-rounds {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      margin-bottom: 10px;

      .br-round {
        flex: 0 1 auto;
        padding: 0 20px;
        position: relative;

        .br-round-title {
          position: absolute;
          top: 0;
          bottom: 0;
          left: -20px;
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.8;

          > span {
            font-size: 13px;
            color: $blue;
            display: block;
            transform: rotate(-90deg);
            transform-origin: center;
            white-space: pre-line;
            position: absolute;
            font-weight: bold;
            text-align: center;
            line-height: 15px;
          }
        }

        &.br-round-next {
          margin-bottom: 45px;

          &.br-advancer-known {
            &:not(.br-advancer-ai) {
              &.br-round-next-positive {
                &::after {
                  border-color: $bracketpositive;
                }
              }
              &.br-round-next-negative {
                &::after {
                  border-color: $bracketnegative;
                }
              }
            }

            &.br-advancer-ai {
              &::after {
                opacity: 0.4;
              }
            }
          }

          &::after {
            content: "";
            display: block;
            top: calc(100% + 4px);
            left: calc(50% - 1px);
            height: 16px;
            position: absolute;
            border-left: 2px solid $bracketborder;
          }
        }

        &.br-round-prev {
          margin-top: 35px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          

          .br-match.br-history {
            flex: 0 0 50%;
            position: relative;

            &::before {
              content: "";
              display: block;
              top: -31px;
              left: calc(50% - 1px);
              height: 16px;
              position: absolute;
              border-left: 2px solid $bracketborder;
            }

            &.br-history-positive {
              &::before {
                border-color: $bracketpositive;
              }
            }

            &.br-history-negative {
              &::before {
                border-color: $bracketnegative;
              }
            }

            .br-player-right {
              padding-right: 0;
            }

            .br-player {
              flex-basis: 50px;

              .rps-avatar-wrapper {
                width: 30px;
                height: 30px;
              }

              .br-username {
                font-size: 12px;
                line-height: 20px;
                height: 20px;
                width: 50px;
              }

              &::before {
                width: 25px;
                top: -17px;
                right: unset;
                left: 24px;
                height: 15px;
              }

              &.br-player-right {
                &::before {
                  left: unset;
                  right: 24px;
                }
              }
            }
          }
        }

        .br-prize {
          font-size: 14px;
          position: absolute;
          left: 0;
          right: 0;
          margin-top: 10px;

          .br-prize-upgrade {
            font-weight: bold;
            color: $lightyellow;
          }

          > div {
            .rps-icon {
              font-size: 20px;
              display: inline-block;
              margin-right: 7px;
              vertical-align: middle;
            }

            .br-prize-name {
              vertical-align: middle;
            }
          }
        }

        .br-advancer {
          text-align: center;
          flex: 1 0 auto;
          display: flex;
          justify-content: center;

          > div {
            flex: 0 1 50px;
            position: relative;
          }

          &.br-advancer-unknown {
            opacity: 0.6;
          }

          &.br-advancer-known {
            &:not(.br-advancer-ai) {
              .br-username {
                font-weight: bold;
              }

              &.br-advancer-positive {
                .br-username {
                  color: $bracketpositive;
                }
              }
              &.br-advancer-negative {
                .br-username {
                  color: $bracketnegative;
                }
              }
            }

            &.br-advancer-ai {
              opacity: 0.4;

              &::after {
                display: none;
              }
            }
          }

          .br-advancer-qm {
            position: absolute;
            font-size: 28px;
            top: 17px;
            left: 20px;
            color: $blue;
            font-family: "Bebas Neue";
          }

          .rps-avatar-wrapper {
            width: 50px;
            height: 50px;
            margin: 5px 0 0;
          }

          .br-username {
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: middle;
            display: inline-block;
            width: 80px;
          }

          &::after {
            content: "";
            display: block;
            height: 20px;
            top: -20px;
            left: calc(50% - 1px);
            position: absolute;
            border-left: 2px dotted $bracketborder;
          }
        }

        .br-match {
          text-align: center;
          position: relative;
          display: flex;
          flex-direction: row;
          justify-content: center;

          .br-match-prog {
            text-align: center;
            flex: 0 1 auto;
            font-size: 12px;
            font-weight: bold;
            padding: 15px 0 0;
            color: #666;
            width: 36px;
          }

          .br-player {
            padding: 0;
            line-height: 0;
            transition: opacity ease 1s;
            flex: 0 0 50px;
            position: relative;

            &.br-player-left {
              &::before {
                border-left: 2px solid $bracketborder;
              }
            }

            &.br-player-right {
              &::before {
                border-right: 2px solid $bracketborder;
                left: unset;
                right: 39px;
              }
            }

            &.br-player-adv {
              .rps-avatar-wrapper {
                margin-top: -2px;
                margin-bottom: -2px;
                border-radius: 999px;
              }

              .br-username {
                font-weight: bold;
              }

              &:not(.br-player-ai) {
                &.br-player-positive {
                  > div {
                    border-color: $bracketpositive;
                  }

                  &::before {
                    border-color: $bracketpositive;
                  }

                  .rps-avatar-wrapper {
                    border: 2px solid $bracketpositive;
                  }

                  .br-username {
                    color: $bracketpositive;
                  }
                }

                &.br-player-negative {
                  > div {
                    border-color: $bracketnegative;
                  }

                  &::before {
                    border-color: $bracketnegative;
                  }

                  .rps-avatar-wrapper {
                    border: 2px solid $bracketnegative;
                  }

                  .br-username {
                    color: $bracketnegative;
                  }
                }
              }
            }

            &.br-player-adv.br-player-ai {
              &::before {
                opacity: 0.4;
              }

              &.br-player-positive {
                .rps-avatar-wrapper {
                  border: 2px solid $bracketpositive;
                }

                .br-username {
                  color: $bracketpositive;
                }
              }

              &.br-player-negative {
                .rps-avatar-wrapper {
                  border: 2px solid $bracketnegative;
                }

                .br-username {
                  color: $bracketnegative;
                }
              }
            }

            &.br-player-ko {
              opacity: 0.4;
            }

            > div {
              position: relative;
            }

            .br-crown {
              position: absolute;
              top: -4px;
              text-align: center;
              left: 0;
              right: 0;
              font-size: 12px;
              color: $goldtext;
            }

            .rps-avatar-wrapper {
              width: 50px;
              height: 50px;
              vertical-align: middle;
              margin: 0;
              box-sizing: content-box;
            }

            .br-username {
              font-size: 14px;
              line-height: 30px;
              height: 30px;
              display: inline-block;
              overflow: hidden;
              text-overflow: ellipsis;
              vertical-align: middle;
              font-weight: bold;
              width: 80px;
            }

            &::before {
              content: "";
              display: block;
              top: -27px;
              height: 25px;
              left: 39px;
              width: 58px;
              position: absolute;
              border-top: 2px solid $bracketborder;
              box-sizing: border-box;
            }
          }

          &.br-match-my {
            // margin-bottom: 50px;

            // .br-player {
            //   .rps-avatar {
            //     width: 35px;
            //     height: 35px;
            //   }

            //   .br-username {
            //     font-weight: bold;
            //     font-size: 14px;
            //     line-height: 30px;
            //     height: 30px;
            //   }

            //   &:first-child {
            //     margin-bottom: 25px;
            //   }

            //   &.br-player-top {
            //     padding-top: 0;
            //   }
            // }

            // &::before {
            //   top: 23px;
            //   bottom: 23px;
            // }
          }

          &.br-resolved {
            &::after {
              border-color: $lightyellow;
            }
          }
        }

        .br-andmore {
          font-size: 13px;
          font-style: italic;
        }
      }
    }
  }

  &.br-first-round {
    .br-rounds {
      .br-round.br-round-prev {
        display: none;
      }
    }
  }

  &.br-last-round {
    .br-rounds {
      .br-round.br-round-next {
        .br-advancer {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}

@keyframes bracketFadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.6;
  }
}
