@import "../../../../styles/variables";

.group-member-badges {
  margin-left: 3px;

  .ui.horizontal.label {
    position: relative;
    top: -1px;
    margin: 0 0 0 4px;
    text-transform: uppercase;
    font-size: 66%;
    letter-spacing: 0.5px;
  }

  .text-label {
    font-size: 13px;
    //font-style: italic;
    opacity: 0.8;
    display: inline-block;
    margin: 0 3px;
  }

  .rps-icon {
    display: inline-block;
    margin: 0 5px;
    position: relative;
    top: 0px;
    font-size: 90%;
    display: inline-block;

    i,
    svg {
      &.member-is-boss {
        color: $yellow;
      }

      &.member-is-assistant {
        color: $lightblue;
      }

      &.member-is-player {
        color: $pink;
      }

      &.member-decision-won, &.member-is-participating {
        color: $green;
      }

      &.member-decision-lost, &.member-not-participating {
        color: $red;
      }

      &.member-is-turn, &.member-repeating {
        color: $blue;
      }

      &.member-not-member {
        color: #999;
      }
    }
  }
}
