@import '../../styles/variables';

.np-ios {
  margin-bottom: 5px !important;
}

.join-wrapper {
  position: relative;

  .ui.button {
    display: block;
    width: 90%;
    margin: 0 auto;
  }
}

.notification-permissions {
  text-align: center;
  margin: 5px 0 13px !important;
  font-size: 12px;
  color: #333;

  &.success {
    color: $green;
    font-size: 14px;
  }
}

.join-wrapper.jw-dag {
  text-align: center;
  margin-bottom: 15px !important;
}

.enable-notifications-prompt-btn {
  display: block;
  margin: 15px 0 0;
  color: $green;
  font-size: 14px;
  line-height: 18px;

  &.icon-only {
    margin: 0;
    font-size: 20px;
    line-height: 20px;
  }
}