@import "../../../../styles/variables";

.component-move-history {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px 0 25px;

  display: flex;
  flex-direction: column;

  &.is-opponent {
    flex-direction: column-reverse;

    .title {
      top: unset;
      bottom: 7px;
    }

    .move {
      margin-top: 2px;
      margin-bottom: 0;
    }
  }

  .title {
    position: absolute;

    top: 1px;
    right: 8px;
    width: 100px;
    text-align: right;
    font-size: 10px;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.5;
  }

  .move {
    flex: 0 0 25px;
    margin-bottom: 2px;
    opacity: 0.8;

    &:first-child {
      flex-basis: 35px;
      opacity: 1;

      img {
        width: 35px;
        height: 35px;
      }
    }

    img {
      width: 25px;
      height: 25px;
    }
  }
}
