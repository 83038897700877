@import "../../../styles/variables";

.modal-decision-options {
  > div.content {
    text-align: left;

    .ui.form {
      margin: 0 auto;
      max-width: 400px;
      font-size: 16px;

      .text-button {
        font-size: 14px;
      }

      .decision-stake-is-positive {
        margin-bottom: 30px !important;
      }

      .time-limit-dropdown {
        margin: 15px auto 10px;
        width: 150px;

        select {
          text-align: center;
        }
      }

      .sip-label {
        font-size: 22px;
        margin-bottom: 20px;

        &.sip-warning {
          color: $red;
          animation: sippulse 1s infinite ease-in-out;
        }
      }

      .sip-stake {
        font-size: 16px;
        font-weight: normal;
        display: flex;
        justify-content: center;
        margin-top: 15px;

        .rps-icon {
          flex: 0 0 15px;
          margin-right: 5px;
          display: inline-block;
        }

        span.sip-text {
          flex: 0 0 auto;
          max-width: 220px;
        }

        &.sip-positive {
          color: $green;
        }

        &.sip-negative {
          color: $red;
        }
      }

      .toggle-container {
        margin-left: -10px;
        margin-right: -10px;
      }

      label {
        margin: 0 0 5px;
        display: block;
        font-weight: bold;
        font-size: 16px;
      }

      .advanced-option-summary-field {
        cursor: pointer;
      }

      .field {
        margin-bottom: 30px;
        text-align: center;

        label {
          span {
            color: #444;
            font-size: 14px;
            position: relative;
            top: 0;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }

        .decision-form-description {
          font-size: 14px;
          line-height: 18px;
          color: #666;
          text-align: center;
          padding: 0 10px;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }

          .popup-trigger {
            color: $blue;
            font-weight: bold;
          }
        }

        input[type="number"],
        .number-dropdown {
          width: 100px;
          margin: 10px 0;
        }

        .advanced-option-summary {
          font-weight: bold;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;

          > div {
            display: inline-block;
            flex: 0 1 auto;
            // border-bottom: 1px dotted #666;
            // padding-bottom: 3px;
          }

          .rps-icon {
            position: relative;
            top: 1px;
            margin-left: 10px;
            margin-right: 3px;

            &:first-child {
              margin-left: 0;
            }
          }

          .text-button {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .actions {
    .ui.icon.primary.button {
      width: 100%;
      margin-left: 0;
    }

    .action-panel-btn-wrap {
      margin-bottom: 0 !important;
    }
  }

  .close-btn {
    margin-left: 0 !important;
  }
}

@keyframes sippulse {
  0% {
    transform: scale(0.99);
    opacity: 1;
  }
  50% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(0.99);
    opacity: 1;
  }
}
